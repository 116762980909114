import React from 'react';

import styles from './styles.module.scss';
import { lokavorLogo } from '../../../assets/images';

const DiscardSubscription: React.FC = () => {
  return (
    <div className={styles.popUpContent}>
      <img src={lokavorLogo} alt="lokavorLogo" />

      <span className={styles.title}>Warnung!</span>
      <span>Wenn Sie zurückgehen, wird Ihr Fortschritt verworfen.</span>
    </div>
  );
};

export default DiscardSubscription;
