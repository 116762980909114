import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Row, Col, Visible } from 'react-grid-system';

import styles from './styles.module.scss';
import '../../assets/styles/css-animations.css';

import LoginForm from './LoginFormLokalac';
import { PopUp } from '../../components/modal';
import { setOpenModalAction } from '../../store/modal';
import { setLottieActive } from '../../store/lottie';
import { AppState } from '../../store/rootReducer';

import history from '../../core/history';
import { confirmUserRegistrationAction } from '../../store/user/actions';
import { realBackground } from '../../assets/images';

const headerContainer = () => {
  return (
    <div className={styles.headerContainer}>
      <Col xs={12} sm={12} md={12}>
        <div className={styles.title}>Login</div>

        <div className={styles.subTitle}>Du hast schon ein Lokavor Nutzerkonto?</div>

        <div className={styles.subTitle}>
          Hier kannst Du Dich mit deiner Email-Adresse und Deinem Passwort einoggen.{' '}
        </div>
      </Col>
    </div>
  );
};

const LoginLokalac: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [clickOnRegistrationButton, setClickOnRegistrationButton] = useState(false);

  const dispatch = useDispatch();

  const { open, source, component, message } = useSelector((state: AppState) => state.modal);
  const { confirmUserRegistrationError } = useSelector((state: AppState) => state.user);

  /* --- VERIFICATION CODE --- */

  const [verificationCode, setVerificationCode] = useState('');

  const userDisabled = () => {
    return (
      <>
        <span>Bitte gib den Bestätigungscode ein, den wir Dir an Deine Mailadresse gesandt haben.</span>
        <input placeholder="Code:" onChange={(e) => setVerificationCode(e.target.value)} />
      </>
    );
  };

  /* --- END VERIFICATION CODE --- */

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(true));

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handlePrimaryButton = () => {
    if (component === 'USER_DISABLED') {
      dispatch(confirmUserRegistrationAction(verificationCode));
    } else dispatch(setOpenModalAction({ open: false, source: '' }));
  };

  const mobileClickOnRegistration = () => {
    setClickOnRegistrationButton(true);
    setTimeout(() => {
      history.push('/registration');
    }, 1000);
  };

  return (
    <>
      <Row className={styles.content}>
        <div className={styles.container}>
          {/* MOBILE PREVIEW */}
          <Visible xs sm>
            <div className={classNames(styles.mainMobileContent, isLoading && styles.mobileTranslate)}>
              <img src={realBackground} alt="dashboard" className={styles.mobileBackgroundImage} />

              <div className={classNames(styles.mainMobileContainer)}>
                <div className={classNames(clickOnRegistrationButton && styles.contentTranslate)}>
                  {headerContainer()}
                  <div className={styles.loginForm}>
                    <LoginForm />
                  </div>
                  <div className={styles.bottomTextContent}>
                    <span>Du hast noch kein Nutzerkonto?</span>
                    <span onClick={mobileClickOnRegistration} role="button" tabIndex={0}>
                      Jetzt neu anmelden
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Visible>

          {/* DESKTOP PREVIEW */}
          <Visible md lg xl xxl>
            <div style={{ opacity: 0 }} className={classNames(isLoading && styles.fadeIn)}>
              {headerContainer()}

              <LoginForm />

              <Col md={12} xs={12} className={styles.bottomTextContent}>
                <span>Du hast noch kein Nutzerkonto?</span>
                <span
                  onClick={() => {
                    dispatch(setLottieActive(true));

                    history.push('/registration');
                  }}
                  role="button"
                  tabIndex={0}
                >
                  Jetzt neu anmelden
                </span>
              </Col>
            </div>
          </Visible>
        </div>
      </Row>

      <PopUp
        isLoadingButton={component === 'USER_DISABLED'}
        secondaryButtonText="Back"
        isSecondaryButton={component === 'USER_DISABLED'}
        open={open && source === 'login'}
        modalContent={
          <div className={styles.loginPopUpContent}>{component === 'USER_DISABLED' ? userDisabled() : message}</div>
        }
        primaryButtonText={component === 'USER_DISABLED' ? 'Weiter' : 'Ok'}
        handlePrimaryButton={handlePrimaryButton}
        disabledButton={component === 'USER_DISABLED' && verificationCode === ''}
        buttonErrors={confirmUserRegistrationError}
      />
    </>
  );
};

export default LoginLokalac;
