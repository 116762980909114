import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IShopRegistration } from '../../models/shop';
import httpClient from '../../core/axios';
import {
  createShopSubscriptionConfig,
  payShopSubscriptionViaInvoiceConfig,
  sendSuccessfulPaymentEmail,
  sendUnsuccessfulPaymentEmail,
} from './routes';
import { packages } from '../../pages/subscription/packages/data';
import { verifyAccessTokenAction } from '../auth/actions';
import { ISubscriptionStore } from '../../models/subscription';
import { setOpenModalAction } from '../modal';
import { deleteShop } from '../shop/actions';
import { ErrorTypes } from '../../enums/errorType';
import { createPaymentIntentConfig, createShopConfig } from '../shop/routes';
import history from '../../core/history';

// PAY SUBSCRIPTION VIA INVOICE
export const payLokavorProductsViaInvoiceAction = createAsyncThunk(
  'subscription/payLokavorProductsViaInvoice',
  async (data: IShopRegistration, { dispatch, getState }) => {
    let shopId;

    try {
      const {
        subscription: { shopData, currency },
      } = getState() as { subscription: ISubscriptionStore };

      const { paymentIntent } = packages(currency);

      const createShopResponse = await httpClient(createShopConfig(), { ...data, registeredType: 'MICROSITE' });

      shopId = createShopResponse.data.id;

      await httpClient(payShopSubscriptionViaInvoiceConfig, {
        shopId,
        lokavorProductPriceId: paymentIntent,
      });

      await httpClient(createShopSubscriptionConfig, {
        shopId,
        lokavorProductPriceId: paymentIntent,
        shopName: shopData?.name,
      });

      await dispatch(verifyAccessTokenAction());

      dispatch(setOpenModalAction({ open: true, source: 'subscriptionCheckout', component: 'SUCCESS_CHECKOUT' }));

      return await Promise.resolve();
    } catch (err) {
      if (shopId) await dispatch(deleteShop(shopId));

      if (axios.isAxiosError(err) && err.response) {
        dispatch(
          setOpenModalAction({
            open: true,
            source: 'subscriptionCheckout',
            message:
              err.response.status === 500
                ? 'Subscription error'
                : ErrorTypes[err.response.data.exceptionType as keyof typeof ErrorTypes],
          })
        );
      }

      return Promise.reject();
    }
  }
);

// PAY SUBSCRIPTION VIA INVOICE
export const payLokavorProductsOnlineAction = createAsyncThunk(
  'subscription/payLokavorProductsOnline',
  async (data: IShopRegistration, { dispatch, getState }) => {
    let shopId;

    try {
      const {
        subscription: { shopData, currency },
      } = getState() as { subscription: ISubscriptionStore };

      const { paymentIntent } = packages(currency);

      const createShopResponse = await httpClient(createShopConfig(), { ...data, registeredType: 'MICROSITE' });

      shopId = createShopResponse.data.id;
      const paymentIntentResponse = await httpClient(createPaymentIntentConfig(), {
        shopId,
        lokavorProductPriceId: paymentIntent,
      });

      const clientSecret = paymentIntentResponse.data;
      history.push('/payment');

      return { clientSecret, shopId };
    } catch (err) {
      if (shopId) await dispatch(deleteShop(shopId));

      if (axios.isAxiosError(err) && err.response) {
        console.log(err.response);
        dispatch(
          setOpenModalAction({
            open: true,
            source: 'subscriptionCheckout',
            message: err.response.status === 500 ? 'Subscription error' : err.response.data.message,
          })
        );
      } else {
        dispatch(
          setOpenModalAction({
            open: true,
            source: 'subscriptionCheckout',
            message: 'Fahler',
          })
        );
      }

      return Promise.reject();
    }
  }
);

// PAY SUBSCRIPTION VIA INVOICE
export const createSubscriptionAction = createAsyncThunk(
  'subscription/createSubscriptionAction',
  async (_, { getState, dispatch }) => {
    try {
      const {
        subscription: { shopData, currency, shopId },
      } = getState() as { subscription: ISubscriptionStore };

      const { paymentIntent } = packages(currency);

      await httpClient(createShopSubscriptionConfig, {
        shopId,
        lokavorProductPriceId: paymentIntent,
        shopName: shopData?.name,
      });
      await httpClient(sendSuccessfulPaymentEmail, {
        shopId,
        currency,
      });
      await dispatch(verifyAccessTokenAction());

      dispatch(setOpenModalAction({ open: true, source: 'subscriptionCheckout', component: 'SUCCESS_CHECKOUT' }));

      return await Promise.resolve();
    } catch (err) {
      const {
        subscription: { shopId, currency },
      } = getState() as { subscription: ISubscriptionStore };

      if (shopId) {
        await dispatch(deleteShop(shopId));
        await httpClient(sendUnsuccessfulPaymentEmail, {
          shopId,
          currency,
        });
      }

      if (axios.isAxiosError(err) && err.response) {
        console.log(err.response);
        dispatch(
          setOpenModalAction({
            open: true,
            source: 'subscriptionCheckout',
            message: err.response.status === 500 ? 'Subscription error' : err.response.data.message,
          })
        );
      } else {
        dispatch(
          setOpenModalAction({
            open: true,
            source: 'subscriptionCheckout',
            message: 'Fahler',
          })
        );
      }

      return Promise.reject();
    }
  }
);
