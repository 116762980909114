import React from 'react';
import { appleStoreBadge, dashboardGroupImage, dashboardGroupImageLokalac, googlePlayBadge } from '../../assets/images';
import styles from './styles.module.scss';

const DownloadLokalacRegistration: React.FC = () => {
  return (
    <div className={styles.content}>
      <div className={styles.desc}>Čestitamo! Registrovali ste korisnički nalog!</div>
      <div className={styles.desc}>Ostao je još samo jedan korak kako biste počeli koristiti Lokalac.</div>
      <div className={styles.desc}>Preuzmite aplikaciju i prijavite sa emailom i lozinkom.</div>
      <img src={dashboardGroupImageLokalac} alt="dashboard_image" />

      <div className={styles.container}>
        <span>LOKALNI PROIZVODI NA MOBILNOM TELEFONU</span>
        <span>
          Preuzmite Lokalac, mobilnu aplikaciju koja povezuje ljubitelje domaćih, ručno rađenih proizvoda sa njihovim
          tvorcima
        </span>
        <div className={styles.badgeContainer}>
          <a href="https://play.google.com/store/apps/details?id=com.lokavor" target="_blank" rel="noreferrer">
            <img src={googlePlayBadge} alt="google-play" />
          </a>
          <a href="https://apps.apple.com/us/app/lokavor/id1517590894" target="_blank" rel="noreferrer">
            <img src={appleStoreBadge} alt="apple-store" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DownloadLokalacRegistration;
