import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Row, Col, Visible } from 'react-grid-system';

import styles from './styles.module.scss';
import '../../../assets/styles/css-animations.css';

import ShopRegistrationForm from '../../../modules/ShopRegistrationForm';
import { getShopTypesAction } from '../../../store/shop/actions';
import { ShopTypes } from '../../../modules/ShopTypes';
import { OpenHours } from '../../../modules/OpenHours';
import { setShopRegistrationDataAction } from '../../../store/shop';
import { IShopRegistration } from '../../../models/shop';
import { setActiveSideAnimation, setShopSubscriptionData } from '../../../store/subscription';
import history from '../../../core/history';
import { PopUp } from '../../../components/modal';

const headerContainer = () => {
  return (
    <div className={styles.headerContainer}>
      <Col xs={12} sm={12} md={12}>
        <div className={styles.title}>Firmendaten</div>
      </Col>
    </div>
  );
};

const desktopSideComponents = (buttonSelectionActive: number | undefined, array: Array<JSX.Element>) => {
  const transitionTimeOut = {
    appear: 500,
    enter: 500,
    exit: 600,
  };
  return array.map((data, i) => {
    return (
      <CSSTransition
        key={i}
        timeout={transitionTimeOut}
        in={buttonSelectionActive === i + 1}
        classNames="shop-registration-side-pages-animation"
        unmountOnExit
      >
        <div className={styles.sideComponentsContainer}>{data}</div>
      </CSSTransition>
    );
  });
};

const mobileOverComponents = (buttonSelectionActive: number | undefined, array: Array<JSX.Element>) => {
  return array.map((data, i) => {
    return (
      <PopUp
        customStylesModal={styles.mobilePopUpContent}
        key={i}
        animation="fadeIn"
        open={buttonSelectionActive === i + 1}
        modalContent={
          <CSSTransition key={i} timeout={400} in={buttonSelectionActive === i + 1} classNames="fade-in" unmountOnExit>
            {data}
          </CSSTransition>
        }
        footer={false}
        customOverlayModalStyles={styles.mobilePopUpOverlay}
      />
    );
  });
};

const ShopRegistrationLokalac: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const [buttonSelectionActive, setButtonSelectionActive] = useState<number | undefined>(undefined);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(true));

    dispatch(getShopTypesAction());

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch]);

  const handleSubmit = (values: IShopRegistration) => {
    dispatch(setShopSubscriptionData(values));

    history.push('/subscription/checkout');
  };

  useEffect(() => {
    dispatch(setActiveSideAnimation(buttonSelectionActive));
  }, [buttonSelectionActive, dispatch]);

  const sideComponents = () => {
    return [
      <ShopTypes
        handleBackButton={() => setButtonSelectionActive(undefined)}
        maxSelectedValues={1}
        callbackHandleSubmit={(value) => dispatch(setShopRegistrationDataAction({ primaryShopType: value[0] }))}
        errorMessage="Sie können nur EINEN Primärtyp für Ihren Shop markieren."
        name="primaryShopType"
        key={1}
        title="Bitte wähle die Hauptkategorie, die am besten zu Deinem Geschäft passt."
      />,
      <ShopTypes
        handleBackButton={() => setButtonSelectionActive(undefined)}
        maxSelectedValues={4}
        callbackHandleSubmit={(value) => dispatch(setShopRegistrationDataAction({ secondaryShopTypes: value }))}
        key={2}
        errorMessage="Sie können bis zu 4 Sekundärtypen für Ihren Shop markieren."
        name="secondaryShopTypes"
        title="Bitte wähle bis zu vier Unterkategorien, die neben der Hauptkategorie zu Deinem Geschäft passen."
      />,
      <OpenHours handleBackButton={() => setButtonSelectionActive(undefined)} key={3} />,
    ];
  };

  return (
    <>
      <Row className={styles.content}>
        <div className={styles.container}>
          {/* MOBILE PREVIEW */}
          <Visible xs sm>
            <div className={classNames(styles.mainMobileContainer, isLoading && styles.fadeIn)}>
              {headerContainer()}

              <ShopRegistrationForm
                callbackSetButtonSelection={(value) => {
                  setButtonSelectionActive((state) => (state === value ? undefined : value));
                }}
                buttonSelectionActive={buttonSelectionActive}
                callbackOnSubmit={(values) => handleSubmit(values)}
              />

              {mobileOverComponents(buttonSelectionActive, sideComponents())}
            </div>
          </Visible>

          {/* DESKTOP PREVIEW */}
          <Visible md lg xl xxl>
            <div className={classNames(styles.registrationContainer, isLoading && styles.fadeIn)}>
              <div className={styles.formContainer}>
                {headerContainer()}

                <ShopRegistrationForm
                  callbackSetButtonSelection={(value) => {
                    setButtonSelectionActive((state) => (state === value ? undefined : value));
                  }}
                  buttonSelectionActive={buttonSelectionActive}
                  callbackOnSubmit={(values) => handleSubmit(values)}
                />
              </div>

              {desktopSideComponents(buttonSelectionActive, sideComponents())}
            </div>
          </Visible>
        </div>
      </Row>
    </>
  );
};

export default ShopRegistrationLokalac;
