import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Visible } from 'react-grid-system';

import classNames from 'classnames';

import styles from './styles.module.scss';
import '../../../assets/styles/css-animations.css';

import { packages } from '../packages/data';
import { trashDelete } from '../../../assets/images';
import RadioButton from '../../../components/radioButton';
import { Button } from '../../../components/button';
import { AppState } from '../../../store/rootReducer';
import { setOpenModalAction } from '../../../store/modal';
import SuccessCheckout from './successCheckoutLokalac';
import { PopUp } from '../../../components/modal';
import { IShopRegistration } from '../../../models/shop';

import history from '../../../core/history';

import { payLokavorProductsViaInvoiceAction } from '../../../store/subscription/actions';

const headerContainer = (shopName: string | undefined) => {
  return (
    <Col md={9} xs={12} sm={12} className={styles.headerContainer}>
      <div className={styles.title}>Meine Bestellung</div>
      <div className={styles.subTitle}>{shopName}</div>
    </Col>
  );
};

const SubscriptionCheckoutLokalac: React.FC = () => {
  const { shopData, status, currency } = useSelector((state: AppState) => state.subscription);
  const { open, source, component, message } = useSelector((state: AppState) => state.modal);

  const [paymentType, setPaymentType] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
  }, [dispatch]);

  const { activePackages, prices } = packages(currency);

  const handleSubmit = async () => {
    dispatch(payLokavorProductsViaInvoiceAction(shopData as IShopRegistration));
  };

  const handlePackageDelete = async () => {
    history.goBack();
  };

  return (
    <>
      <Row className={styles.content}>
        <div className={classNames(styles.container, isLoading && styles.fadeIn)}>
          <div className={styles.firstContainer}>
            {headerContainer(shopData?.name)}

            <Col md={9} xs={12} sm={12}>
              {activePackages.map((data, i) => {
                return (
                  <div key={i} className={styles.packageRow}>
                    <span onClick={handlePackageDelete} role="button" tabIndex={0}>
                      <img src={trashDelete} alt="delete" className={styles.trachIcon} />
                    </span>

                    <div className={styles.packageRowInfo}>
                      <div>
                        <Visible md xl xxl>
                          {data.titleImage}
                        </Visible>

                        <span>{data.title}</span>
                      </div>

                      <div>
                        <span className={styles.price}>{`${data.price}`}</span>
                        <span className={styles.discountPrice}>&nbsp;{`${data.discountPrice}`}&nbsp;</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Col>

            <Col md={9} xs={12} sm={12}>
              <div className={styles.totalPrice}>{`Gesamtbetrag ${prices.discountPrice}`}</div>
            </Col>
          </div>

          <Col md={9} xs={12} sm={12}>
            <div className={styles.paymentTypeContainer}>
              <span className={styles.paymentTypeTitle}>Bezahlmethode wähle</span>

              <div className={styles.radioButtonContainer}>
                <RadioButton
                  active={paymentType === 1}
                  callbackClickOnRadioButton={() => setPaymentType(1)}
                  label="Auf Rechnung"
                  radioButtonContentStyles={styles.radioButtonContentStyles}
                />

                <RadioButton
                  active={paymentType === 2}
                  callbackClickOnRadioButton={() => setPaymentType(2)}
                  disabled
                  label="Kreditkarte"
                />
              </div>
            </div>
          </Col>

          <div className={styles.buttonContent}>
            <Button
              isLoading={status !== 'success'}
              callbackOnClick={handleSubmit}
              label="Jetzt kostenpflichtig bestellen"
              buttonContentStyles={styles.buttonContentStyles}
              errors={status === 'error'}
            />
          </div>
        </div>
      </Row>

      <PopUp
        open={open && source === 'subscriptionCheckout'}
        modalContent={
          <div className={styles.checkoutPopup}>{component === 'SUCCESS_CHECKOUT' ? <SuccessCheckout /> : message}</div>
        }
        primaryButtonText="Ok"
        handlePrimaryButton={() => {
          if (component === 'SUCCESS_CHECKOUT') history.push('/dashboard');

          dispatch(setOpenModalAction({ open: false, source: '' }));
        }}
      />
    </>
  );
};

export default SubscriptionCheckoutLokalac;
