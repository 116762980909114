import { createSlice } from '@reduxjs/toolkit';
import { ISubscriptionStore } from '../../models/subscription';
import {
  createSubscriptionAction,
  payLokavorProductsOnlineAction,
  payLokavorProductsViaInvoiceAction,
} from './actions';

const initialState: ISubscriptionStore = {
  currency: 'CHF',
  shopData: undefined,
  status: 'idle',
  activeSideAnimation: false,
  clientSecret: undefined,
  clientSecretStatus: 'idle',
  shopId: undefined,
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(payLokavorProductsViaInvoiceAction.pending, (state) => {
      return {
        ...state,
        status: 'loading',
      };
    });

    builder.addCase(payLokavorProductsViaInvoiceAction.rejected, (state) => {
      return {
        ...state,
        status: 'error',
      };
    });

    builder.addCase(payLokavorProductsViaInvoiceAction.fulfilled, (state) => {
      return {
        ...state,
        status: 'success',
      };
    });
    builder.addCase(payLokavorProductsOnlineAction.pending, (state) => {
      return {
        ...state,
        clientSecretStatus: 'loading',
      };
    });
    builder.addCase(payLokavorProductsOnlineAction.fulfilled, (state, action) => {
      return {
        ...state,
        clientSecretStatus: 'success',
        clientSecret: action.payload?.clientSecret,
        shopId: action.payload?.shopId,
      };
    });
    builder.addCase(payLokavorProductsOnlineAction.rejected, (state) => {
      return {
        ...state,
        clientSecretStatus: 'error',
      };
    });
    builder.addCase(createSubscriptionAction.pending, (state) => {
      return {
        ...state,
        status: 'loading',
      };
    });
    builder.addCase(createSubscriptionAction.fulfilled, (state, action) => {
      return {
        ...state,
        status: 'success',
      };
    });
    builder.addCase(createSubscriptionAction.rejected, (state) => {
      return {
        ...state,
        status: 'error',
      };
    });
  },
  reducers: {
    setShopSubscriptionData: (state, action) => {
      return {
        ...state,
        shopData: action.payload,
      };
    },

    setActiveSideAnimation: (state, action) => {
      return {
        ...state,
        activeSideAnimation: action.payload,
      };
    },

    setCurrencyAction: (state, action) => ({
      ...state,
      currency: action.payload,
    }),

    setShopIdAction: (state, action) => ({
      ...state,
      shopId: action.payload,
    }),
  },
});

export const { setCurrencyAction, setShopSubscriptionData, setActiveSideAnimation, setShopIdAction } =
  subscriptionSlice.actions;
