import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useDispatch, useSelector } from 'react-redux';

import styles from '../styles.module.scss';
import '../../../../assets/styles/css-animations.css';

import { lokavorLogo } from '../../../../assets/images';
import { IHeaderComponent } from '../../../../models/header';
import { logOutAction } from '../../../../store/auth';
import { AppState } from '../../../../store/rootReducer';
import history from '../../../../core/history';

export const HeaderDesktop: React.FC<IHeaderComponent> = ({ navData }: IHeaderComponent) => {
  const dispatch = useDispatch();
  const { isLogin } = useSelector((state: AppState) => state.auth);

  const [toggleNav, setToggleNav] = useState<{ open: boolean; index: undefined | number }>({
    open: false,
    index: undefined,
  });

  return (
    <div className={styles.headerContent}>
      <div className={styles.headerContainer}>
        <a href="https://www.lokavor.de/" target="_blank" rel="noreferrer">
          <img src={lokavorLogo} alt="lokavorLogo" />
        </a>

        <div className={styles.navContent}>
          {navData.map((navDataItem) => {
            return (
              <div
                key={navDataItem.index}
                className={styles.navContainer}
                onMouseEnter={() => setToggleNav({ open: true, index: navDataItem.index })}
                onMouseLeave={() => setToggleNav({ open: false, index: navDataItem.index })}
              >
                <span
                  className={styles.mainNavText}
                  onClick={() => navDataItem.name === 'Ausloggen' && dispatch(logOutAction())} // WILL BE ALTERED IN NEXT PLANS
                  role="button"
                  tabIndex={0}
                >
                  {navDataItem.name}
                </span>
                <CSSTransition
                  in={toggleNav.open && toggleNav.index === navDataItem.index && Array.isArray(navDataItem.navItems)}
                  timeout={300}
                  classNames="fade-in"
                  unmountOnExit
                >
                  <div className={styles.navItemsContainer}>
                    {Array.isArray(navDataItem.navItems) &&
                      navDataItem.navItems.map((item) => {
                        return (
                          <a href={item.url} className={styles.navItem} key={item.key} target="_blank" rel="noreferrer">
                            {item.name}
                          </a>
                        );
                      })}
                  </div>
                </CSSTransition>
              </div>
            );
          })}

          {isLogin && (
            <div className={styles.navContainer}>
              <span
                className={styles.mainNavText}
                onClick={() => dispatch(logOutAction())} // WILL BE ALTERED IN NEXT PLANS
                role="button"
                tabIndex={0}
              >
                Ausloggen
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
