import { createSlice } from '@reduxjs/toolkit';

export interface IModalStore {
  open: boolean;
  source: string;
  component?: string | undefined;
  message: string;
  title: string;
}

const initialState: IModalStore = {
  open: false,
  source: '',
  component: '',
  message: '',
  title: '',
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setOpenModalAction: (state, action) => ({
      ...state,
      open: action.payload.open,
      source: action.payload.source,
      component: action.payload.component,
      message: action.payload.message,
      title: action.payload.title,
    }),
  },
});

export const { setOpenModalAction } = modalSlice.actions;
