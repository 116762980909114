import React, { useState } from 'react';
import classNames from 'classnames';

import { Col, Visible } from 'react-grid-system';
import { useSelector, useDispatch } from 'react-redux';

import styles from './styles.module.scss';
import '../../../assets/styles/css-animations.css';

import { discountBadge } from '../../../assets/images';
import { Button } from '../../../components/button';
import { packagesLokalac } from './data';
import history from '../../../core/history';
import { AppState } from '../../../store/rootReducer';
import { setCurrencyAction } from '../../../store/subscription';

const PackagesLokalac: React.FC = () => {
  const dispatch = useDispatch();
  const [clickOnButton, isClickOnButton] = useState(false);

  const { currency } = useSelector((state: AppState) => state.subscription);
  const { isLogin } = useSelector((state: AppState) => state.auth);

  const headerContainer = () => {
    return (
      <div className={styles.headerContainer}>
        <Col xs={12} sm={12} md={12}>
          <div className={styles.title}>
            <span>Uzmi sad!</span>
            <span>2 Paket u pola cene!</span>
          </div>

          <div className={styles.changeCurrencyContainer}>
            <div>
              <span onClick={() => dispatch(setCurrencyAction('CHF'))} role="button" tabIndex={0}>
                &nbsp;CHF&nbsp;
              </span>

              {currency === 'CHF' && <span className={styles.bottomLine} />}
            </div>

            <div>
              <span onClick={() => dispatch(setCurrencyAction('EUR'))} role="button" tabIndex={0}>
                &nbsp;EUR&nbsp;
                {currency === 'EUR' && <span className={styles.bottomLine} />}
              </span>
            </div>
          </div>
        </Col>
      </div>
    );
  };

  const { activePackages, prices } = packagesLokalac(currency);

  const handleButton = () => {
    isClickOnButton(true);
    setTimeout(() => {
      history.push(!isLogin ? '/login-rs' : '/subscription/shop/registration-rs');
    }, 300);
  };

  return (
    <>
      <div className={styles.content}>
        <div className={classNames(styles.container, styles.fadeIn, clickOnButton && styles.slideToTop)}>
          {headerContainer()}

          <div className={styles.productContainer}>
            {activePackages.map((packageData) => {
              return (
                <div className={styles.productBox} key={packageData.key} style={packageData.styles}>
                  <div>
                    <div className={styles.title}>
                      {packageData.title}
                      {packageData.titleImage}{' '}
                    </div>
                    <div className={styles.subTitle}>{packageData.subTitle}</div>

                    <ul>
                      {packageData.list.map((name: string, j: number) => {
                        return <li key={j}>{name}</li>;
                      })}
                    </ul>
                  </div>

                  <div className={styles.price}>&nbsp;{packageData.price}&nbsp;</div>
                </div>
              );
            })}
          </div>

          <div className={styles.priceInfoContainer}>
            <div className={styles.mobilePreview}>
              <span>Godisnja pretplata za oba paketa</span>

              <Visible xs sm>
                <img src={discountBadge} alt="discountBadge" />
              </Visible>
            </div>

            <div>
              <span>{prices.discountPrice}</span>
              <span>&nbsp;umesto {prices.totalPrice}</span>

              <Visible md lg xl xxl>
                <img src={discountBadge} alt="discountBadge" />
              </Visible>
            </div>
            <span>= {prices.mounthPrice}/Mesec</span>
          </div>

          <div className={styles.buttonContent}>
            <Button callbackOnClick={handleButton} label="Naruci ovde" />
          </div>
        </div>
      </div>
    </>
  );
};

export default PackagesLokalac;
