import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Visible, useScreenClass } from 'react-grid-system';
import { Route, useLocation } from 'react-router-dom';

import styles from './styles.module.scss';
import { IHeader } from '../../models/header';

import { Background } from './Background';
import { HeaderDesktop } from './Header/Desktop';
import { HeaderMobile } from './Header/Mobile';

import { AppState } from '../../store/rootReducer';

const navData: IHeader[] = [
  // {
  //   index: 1,
  //   name: 'Test',
  //   navItems: { key: 1, name: 'Datenschutzrichtlinien', url: 'https://www.lokavor.de/datenschutzrichtlinien.html' },
  // },
  // {
  //   index: 2,
  //   name: 'Produkte',
  //   navItems: [
  //     // { key: 1, name: 'Lokavor Microsite', url: 'https://www.lokavor.de/#Microsite' },
  //     // { key: 2, name: 'Lokavor QuickShop', url: 'https://www.lokavor.de/#Quickshop' },
  //   ],
  // },
  // {
  //   index: 3,
  //   name: 'Über Lokavor',
  //   navItems: [
  //     // { key: 1, name: 'Datenschutzrichtlinien', url: 'https://www.lokavor.de/datenschutzrichtlinien.html' },
  //     // { key: 2, name: 'Nutzungsbedingungen', url: 'https://www.lokavor.de/nutzungsbedingungen.html' },
  //     // { key: 3, name: 'AGB', url: 'https://www.lokavor.de/agb.html' },
  //     // { key: 4, name: 'Impressum', url: 'https://www.lokavor.de/impressum.html' },
  //   ],
  // },
];

interface ILayout {
  path: string | string[];
  component: JSX.Element;
}

const Layout: React.FC<ILayout> = ({ path, component }: ILayout) => {
  const { open } = useSelector((state: AppState) => state.modal);
  const screenClass = useScreenClass();

  const location = useLocation();

  const handleLocationBackgroundColor = () => {
    if (location.pathname === '/subscription/shop/registration' && ['md', 'lg', 'xl', 'xxl'].includes(screenClass)) {
      return styles.nonBackgroundColor;
    }
    return null;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div
      className={classNames(
        styles.content,
        open && styles.blur,
        handleLocationBackgroundColor(),
        location.pathname === '/sei-dabei'
      )}
    >
      {/* Desktop preview */}
      <Visible md lg xl xxl>
        {/* Lottie content */}
        <Background />

        <HeaderDesktop navData={navData} />
      </Visible>

      {/* Mobile preview */}
      <Visible xs sm>
        <HeaderMobile navData={navData} />
      </Visible>

      <Route path={path}>
        <main>{component}</main>
      </Route>
    </div>
  );
};

export default Layout;
