import { createSlice } from '@reduxjs/toolkit';
import { ShopTypes } from '../../enums/shop';
import { IShopStore, IShopType } from '../../models/shop';
import { createShop, getShopTypesAction } from './actions';
import { initOpenHoursDto } from './initValues';

const initialState: IShopStore = {
  shopTypes: [],
  shopRegistration: {
    primaryShopType: '',
    secondaryShopTypes: [],
    openHoursDto: initOpenHoursDto,
  },
};

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setShopRegistrationDataAction: (state, action) => {
      return {
        ...state,
        shopRegistration: { ...state.shopRegistration, ...action.payload },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShopTypesAction.fulfilled, (state, action) => {
      const shopTypes = action.payload.map((data: IShopType) => {
        return { ...data, label: ShopTypes[data.name as keyof typeof ShopTypes] };
      });

      return {
        ...state,
        shopTypes: shopTypes
          .sort((a, b) => a.label.localeCompare(b.label))
          .filter((type) => type.label !== 'Wochenmarkt'),
      };
    });
    builder.addCase(getShopTypesAction.rejected, (state) => {
      return {
        ...state,
      };
    });
    builder.addCase(createShop.fulfilled, (state, action) => {
      const shopId = action.payload.id;
      return {
        ...state,
        shopId,
      };
    });
  },
});

export const { setShopRegistrationDataAction } = shopSlice.actions;
