import { IOpenHoursDto } from '../../models/shop';

export const initOpenHoursDto: IOpenHoursDto[] = [
  {
    name: 'Mo',
    dayOfWeek: 'MONDAY',
    from: '08:00',
    to: '18:00',
    nonWorkingDay: false,
    breakFrom: '09:00',
    breakTo: '09:30',
  },
  {
    name: 'Di',
    dayOfWeek: 'TUESDAY',
    from: '08:00',
    to: '18:00',
    nonWorkingDay: false,
    breakFrom: '09:00',
    breakTo: '09:30',
  },
  {
    name: 'Mi',
    dayOfWeek: 'WEDNESDAY',
    from: '08:00',
    to: '18:00',
    nonWorkingDay: false,
    breakFrom: '09:00',
    breakTo: '09:30',
  },
  {
    name: 'Do',
    dayOfWeek: 'THURSDAY',
    from: '08:00',
    to: '18:00',
    nonWorkingDay: false,
    breakFrom: '09:00',
    breakTo: '09:30',
  },
  {
    name: 'Fr',
    dayOfWeek: 'FRIDAY',
    from: '08:00',
    to: '18:00',
    nonWorkingDay: false,
    breakFrom: '09:00',
    breakTo: '09:30',
  },
  {
    name: 'Sa',
    dayOfWeek: 'SATURDAY',
    from: '08:00',
    to: '18:00',
    nonWorkingDay: false,
    breakFrom: '09:00',
    breakTo: '09:30',
  },
  {
    name: 'So',
    dayOfWeek: 'SUNDAY',
    from: '08:00',
    to: '18:00',
    nonWorkingDay: false,
    breakFrom: '09:00',
    breakTo: '09:30',
  },
];
