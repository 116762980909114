import { createSlice } from '@reduxjs/toolkit';
import { registrationAction, confirmUserRegistrationAction } from './actions';

interface IUserStore {
  registrationError: boolean;
  confirmUserRegistrationError: boolean;
}

const initialState: IUserStore = {
  registrationError: false,
  confirmUserRegistrationError: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* REGISTRATION ACTION */

    builder.addCase(registrationAction.pending, (state) => {
      return {
        ...state,
        registrationError: false,
      };
    });

    builder.addCase(registrationAction.rejected, (state) => {
      return {
        ...state,
        registrationError: true,
      };
    });

    /* CONFIRM USER REGISTRATION ACTION */

    builder.addCase(confirmUserRegistrationAction.pending, (state) => {
      return {
        ...state,
        confirmUserRegistrationError: false,
      };
    });

    builder.addCase(confirmUserRegistrationAction.fulfilled, (state) => {
      return {
        ...state,
        confirmUserRegistrationError: false,
      };
    });

    builder.addCase(confirmUserRegistrationAction.rejected, (state) => {
      return {
        ...state,
        confirmUserRegistrationError: true,
      };
    });
  },
});
