export enum ErrorTypes {
  USER_NOT_FOUND = 'Unbekannte Email-Adresse',
  AUTHORIZATION_FAILED = 'Ungültiges Passwort.',
  USER_LOCKED = 'Der Zugriff ist vorübergehend gesperrt. Bitte warte 5 Minuten und versuche es dann erneut.',
  VALIDATION_FAILED = 'Bitte beachte unsere Mindestanforderung für Passwörter: Mindestens ein Grossbuchstabe, eine Zahl und sechs Zeichen.',
  shop_type_already_exists_on_address = 'Ein Geschäft dieser Kategorie existiert bereits unter dieser Adresse in unserer Datenbank.',
  shop_name_already_exists_on_address = 'Ein Geschäft dieses Namens existiert bereits unter dieser Adresse in unserer Datenbank.',
  stripe_combine_currencies_failed_already_chf = 'Hoppla! Das vorliegende Abonnement wurde in CHF gebucht und kann dementsprechend auch nur in CHF verlängert werden. Bitte passe die Währung entsprechend an.',
  stripe_combine_currencies_failed_already_eur = 'Hoppla! Das vorliegende Abonnement wurde in Euro gebucht und kann dementsprechend auch nur in Euro verlängert werden. Bitte passe die Währung entsprechend an.',
}

export const ErrorCodeTypes = {
  409: 'Ein Nutzer mit dieser Email-Adresse existiert bereits.',
};
