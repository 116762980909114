import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Row, Col, Visible } from 'react-grid-system';

import styles from './styles.module.scss';
import '../../assets/styles/css-animations.css';

import RegistrationForm from './RegistrationForm';
import { PopUp } from '../../components/modal';
import { AppState } from '../../store/rootReducer';
import { setOpenModalAction } from '../../store/modal';
import { setLottieActive } from '../../store/lottie';
import history from '../../core/history';
import { realBackground } from '../../assets/images';
import { urlSearchParameters } from '../../utils/urlSearchParams';

const renderSubtitleText = (search: string | undefined) => {
  const query = urlSearchParameters(search);

  if (query.get('shopId') && query.get('shopName'))
    return `Willkommen bei Lokavor und ${query.get(
      'shopName'
    )}! Sobald Du Dich als Nutzer registriert hast, wird Dich  ${query.get(
      'shopName'
    )} immer auf dem Laufenden halten.`;

  if (query.get('shopId') || query.get('source')) {
    return 'Willkommen bei Lokavor. Bitte melde Dich zur kostenlosen Nutzung unserer App hier an.';
  }

  return 'Um die Lokavor Services nutzen zu können, benötigst Du zuerst ein Nutzerkonto.';
};

const headerContainer = (location: string | undefined) => {
  return (
    <div className={styles.headerContainer}>
      <Col md={12} xs={12} sm={12}>
        <div className={styles.title}>Neues Nutzerkonto anmelden</div>
      </Col>
      <Col md={12} sm={12}>
        <div className={styles.subTitle}>{renderSubtitleText(location)}</div>
      </Col>
    </div>
  );
};

const Registration: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  const dispatch = useDispatch();

  const { open, source, message } = useSelector((state: AppState) => state.modal);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(true));

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handlePrimaryButton = () => {
    dispatch(setOpenModalAction({ open: false, source: '' }));
  };

  return (
    <>
      <Row className={styles.content}>
        <div className={styles.container}>
          {/* MOBILE PREVIEW */}
          <Visible xs sm>
            <>
              <img
                src={realBackground}
                alt="dashboard"
                className={classNames(isLoading && styles.imageResize, styles.mobileBackgroundImage)}
              />
              <div className={classNames(styles.mobileContent, isLoading && styles.contentResize)}>
                <div className={classNames(styles.mobileContainer, isLoading && styles.mobileTranslate)}>
                  {headerContainer(location.search)}

                  <div>
                    <RegistrationForm />
                  </div>

                  <div className={styles.redirectToLoginContent}>
                    <span>
                      Du hast bereits ein Konto?&nbsp;
                      <span
                        className={styles.link}
                        onClick={() => {
                          dispatch(setLottieActive(true));
                          history.push('login');
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        Hier geht&lsquo;s zum Login.
                      </span>{' '}
                    </span>
                  </div>
                  <div>
                    <Col md={2} className={styles.termsContent}>
                      <span>
                        Ich stimme den{' '}
                        <span
                          onClick={() => {
                            window.location.href = 'https://www.lokavor.ch/nutzungsbedingungen';
                          }}
                          role="button"
                          tabIndex={0}
                          className={styles.link}
                        >
                          Nutzungsbedingungen
                        </span>{' '}
                        und{' '}
                        <span
                          onClick={() => {
                            window.location.href = 'https://www.lokavor.ch/datenschutzrichtlinienweb';
                          }}
                          className={styles.link}
                          role="button"
                          tabIndex={0}
                        >
                          Datenschutzrichtlinien
                        </span>{' '}
                        zu.
                      </span>
                    </Col>
                  </div>
                </div>
              </div>
            </>
          </Visible>

          {/* DESKTOP PREVIEW */}
          <Visible md lg xl xxl>
            <div style={{ opacity: 0 }} className={classNames(isLoading && styles.fadeIn)}>
              {headerContainer(location.search)}

              <RegistrationForm />

              <Col md={12} className={styles.redirectToLoginContent}>
                <span>
                  Du hast bereits ein Konto?&nbsp;
                  <span
                    className={styles.link}
                    onClick={() => {
                      dispatch(setLottieActive(true));
                      history.push('login');
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    Hier geht&lsquo;s zum Login.
                  </span>{' '}
                </span>
              </Col>

              <Col md={4} className={styles.termsContent}>
                <span>
                  Ich stimme den{' '}
                  <span
                    onClick={() => {
                      window.location.href = 'https://www.lokavor.ch/nutzungsbedingungen';
                    }}
                    className={styles.link}
                    role="button"
                    tabIndex={0}
                  >
                    Nutzungsbedingungen
                  </span>{' '}
                  und{' '}
                  <span
                    onClick={() => {
                      window.location.href = 'https://www.lokavor.ch/datenschutzrichtlinienweb';
                    }}
                    className={styles.link}
                    role="button"
                    tabIndex={0}
                  >
                    Datenschutzrichtlinien
                  </span>{' '}
                  zu.
                </span>
              </Col>
            </div>
          </Visible>
        </div>
      </Row>

      <PopUp
        // secondaryButtonText="Back"
        // isSecondaryButton
        open={open && source === 'registration'}
        modalContent={<div className={styles.registrationPopUpContent}>{message}</div>}
        primaryButtonText="OK"
        handlePrimaryButton={handlePrimaryButton}
      />
    </>
  );
};

export default Registration;
