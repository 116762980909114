import { createSlice } from '@reduxjs/toolkit';
import history from '../../core/history';
import { IAuthStore } from '../../models/auth';
import { loginAction, verifyAccessTokenAction } from './actions';

const initialState: IAuthStore = {
  loginError: false,
  access_token: '',
  refresh_token: '',
  name: '',
  isLogin: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOutAction: (state) => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');

      history.push('/login');

      return {
        ...state,
        isLogin: false,
        access_token: '',
        refresh_token: '',
      };
    },
  },
  extraReducers: (builder) => {
    /* LOGIN ACTION */

    builder.addCase(loginAction.pending, (state) => {
      return {
        ...state,
        loginError: false,
      };
    });

    builder.addCase(loginAction.fulfilled, (state, { payload }) => {
      return {
        ...state,
        loginError: false,
        isLogin: true,
        access_token: payload.access_token,
        refresh_token: payload.refresh_token,
      };
    });
    builder.addCase(loginAction.rejected, (state) => {
      return {
        ...state,
        loginError: true,
        isLogin: false,
      };
    });

    // VERIFY TOKEN ACTION //

    builder.addCase(verifyAccessTokenAction.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isLogin: true,
        name: payload.name,
      };
    });

    builder.addCase(verifyAccessTokenAction.rejected, (state) => {
      return {
        ...state,
        isLogin: false,
      };
    });
  },
});

export const { logOutAction } = authSlice.actions;
