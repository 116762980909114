import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ErrorCodeTypes, ErrorTypes } from '../../enums/errorType';
import { IUserRegistration } from '../../models/registration';
import history from '../../core/history';
import { setOpenModalAction } from '../modal';
import { registrationEndPoint, confirmUserRegistrationEndPoint, updateUserWithAmbassadorEndPoint } from './routes';
import { urlSearchParameters } from '../../utils/urlSearchParams';
import { ErrorCodeTypesLokalac, ErrorTypesLokalac } from '../../enums/errorTypeLokalac';

export const registrationAction = createAsyncThunk(
  'user/registrationAction',
  async (registrationUserData: IUserRegistration, { dispatch }) => {
    try {
      const { searchParameters, ...formData } = registrationUserData;

      const query = urlSearchParameters(searchParameters);
      let queryEndpoint = '';

      if (query.get('shopId')) {
        if (query.get('source')) {
          queryEndpoint = `?shopId=${query.get('shopId')}&sourceId=${query.get('source')}`;
        } else {
          queryEndpoint = `?shopId=${query.get('shopId')}&sourceId=2`;
        }
      } else if (query.get('source')) {
        queryEndpoint = `?sourceId=${query.get('source')}`;
      } else if (query.get('voucherId')) {
        queryEndpoint = `?voucherId=${query.get('voucherId')}`;
      } else if (query.get('userId')) {
        queryEndpoint = `?ambassadorId=${query.get('userId')}`;
      }

      const headers = {
        'Content-Type': 'application/json',
        'Accept-Language': 'DE', // Use the 'appLanguage' value from the redux state
      };

      const response = await axios.post(registrationEndPoint(queryEndpoint), formData, { headers });
      // if (query.get('userId')) {
      //   const ambassadorParam = `?ambassadorId=${query.get('userId')}`;
      //   try {
      //     await axios.put(updateUserWithAmbassadorEndPoint(ambassadorParam));
      //   } catch (e) {
      //     console.log(e);
      //   }
      // }

      if (query.get('shopId') || query.get('source')) {
        history.push('/download');
      } else {
        history.push('/login');

        dispatch(
          setOpenModalAction({
            open: true,
            source: 'login',
            message: 'Klasse, Deine Anmeldung war erfolgreich!',
          })
        );
      }

      return response.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        if (!err.response) {
          throw err;
        }

        if (err.response.data.exceptionType) {
          dispatch(
            setOpenModalAction({
              open: true,
              source: 'registration',
              message: ErrorTypes[err.response.data.exceptionType as keyof typeof ErrorTypes],
            })
          );

          return Promise.reject();
        }

        dispatch(
          setOpenModalAction({
            open: true,
            source: 'registration',
            message: ErrorCodeTypes[err.response.status as keyof typeof ErrorCodeTypes],
          })
        );
      }

      return Promise.reject();
    }
  }
);

// Confirm User Registration Action
export const confirmUserRegistrationAction = createAsyncThunk(
  'user/registrationConfirmAction',
  async (code: string, { dispatch }) => {
    try {
      const response = await axios.post(confirmUserRegistrationEndPoint, { code });

      dispatch(
        setOpenModalAction({
          open: true,
          source: 'login',
          message: 'Du hast Dich erfolgreich registriert.',
          component: '',
        })
      );

      return response.data;
    } catch (err) {
      dispatch(
        setOpenModalAction({
          open: true,
          source: 'login',
          message: 'Der eingegebene Code existiert nicht, oder wurde bereits verwendet.' as string,
        })
      );

      return Promise.reject();
    }
  }
);

export const registrationActionLokalac = createAsyncThunk(
  'user/registrationAction',
  async (registrationUserData: IUserRegistration, { dispatch }) => {
    try {
      const { searchParameters, ...formData } = registrationUserData;

      const query = urlSearchParameters(searchParameters);
      let queryEndpoint = '';

      if (query.get('shopId')) {
        if (query.get('source')) {
          queryEndpoint = `?shopId=${query.get('shopId')}&sourceId=${query.get('source')}`;
        } else {
          queryEndpoint = `?shopId=${query.get('shopId')}&sourceId=2`;
        }
      } else if (query.get('source')) {
        queryEndpoint = `?sourceId=${query.get('source')}`;
      } else if (query.get('voucherId')) {
        queryEndpoint = `?voucherId=${query.get('voucherId')}`;
      }

      const headers = {
        'Content-Type': 'application/json',
        'Accept-Language': formData.country === 'SERBIA' ? 'SR' : 'BA', // Use the 'appLanguage' value from the redux state
      };

      const response = await axios.post(registrationEndPoint(queryEndpoint), formData, {
        headers,
      });

      if (query.get('shopId') || query.get('source')) {
        history.push('/download-rs-registration');
      } else {
        history.push('/download-rs-registration');

        dispatch(
          setOpenModalAction({
            open: true,
            source: 'login',
            message: 'Klasse, Deine Anmeldung war erfolgreich!',
          })
        );
      }

      return response.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        if (!err.response) {
          throw err;
        }

        if (err.response.data.exceptionType) {
          dispatch(
            setOpenModalAction({
              open: true,
              source: 'registration',
              message: ErrorTypesLokalac[err.response.data.exceptionType as keyof typeof ErrorTypesLokalac],
            })
          );

          return Promise.reject();
        }

        dispatch(
          setOpenModalAction({
            open: true,
            source: 'registration',
            message: ErrorCodeTypesLokalac[err.response.status as keyof typeof ErrorCodeTypesLokalac],
          })
        );
      }

      return Promise.reject();
    }
  }
);
