import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { RefreshResult } from '../../models/axios';
import { configKeycloak, validateAccessTokenEndPoint } from './environment';

// *------- Axios interseptors -------- *//
// transformRequest: [
//   function (data, headers) {
//     // the body of the post request must be serialized in order to conform to 'application/x-www-form-urlencoded'
//     // return qs.stringify(data);
//   },
// ],
// headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
// });

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('access_token');

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// Added the 401 response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401) {
      const refreshToken = localStorage.getItem('refresh_token');
      if (refreshToken) {
        try {
          const result: RefreshResult = await axios.post(validateAccessTokenEndPoint, {
            configKeycloak,
          });

          localStorage.setItem('access_token', result.accessToken);
          localStorage.setItem('refresh_token', result.refreshToken as string);

          axiosInstance.defaults.headers.Authorization = `Bearer ${result.accessToken}`;

          return await axiosInstance(originalRequest);
        } catch (e) {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');

          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error);
  }
);
// *-------------------------------------- *//

export default async (
  config: { method: string; url: string },
  data?: unknown | string,
  headers?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  switch (config.method) {
    case 'POST':
      return axiosInstance.post(config.url, data, headers);
    case 'DELETE':
      return axiosInstance.delete(config.url, undefined);
    case 'PUT':
      return axiosInstance.put(config.url, data, headers);
    case 'GET':
      return axiosInstance.get(config.url, headers);
    default:
      return Promise.reject();
  }
};
