export const CountryInfo = {
  germany: {
    locale: 'de',
    countryCode: 'DE',
  },
  switzerland: {
    locale: 'de',
    countryCode: 'CH',
  },
  'bosnia and herzegovina': {
    locale: 'de',
    countryCode: 'DE',
  },
};
