import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Visible, useScreenClass } from 'react-grid-system';
import { Route, useLocation } from 'react-router-dom';

import styles from '../styles.module.scss';
import { IHeader } from '../../../models/header';

import { Background } from '../Background';

import { AppState } from '../../../store/rootReducer';
import { HeaderMobileLokalac } from '../HeaderLokalac/Mobile';
import { HeaderDesktopLokalac } from '../HeaderLokalac/Desktop';

const navData: IHeader[] = [
  {
    index: 2,
    name: 'Postani korisnik',
  },

  {
    index: 3,
    name: 'O Lokalcu',
    navItems: [
      { key: 1, name: 'Uslovi koriscenja RS', url: '/terms-rs' },
      { key: 2, name: 'Uslovi koriscenja BiH', url: '/terms-bih' },
    ],
  },
];

interface ILayout {
  path: string | string[];
  component: JSX.Element;
  hideHeaderMenuItems?: boolean;
}

const LayoutLokalac: React.FC<ILayout> = ({ path, component, hideHeaderMenuItems }: ILayout) => {
  const { open } = useSelector((state: AppState) => state.modal);
  const screenClass = useScreenClass();

  const location = useLocation();

  const handleLocationBackgroundColor = () => {
    if (location.pathname === '/subscription/shop/registration-rs' && ['md', 'lg', 'xl', 'xxl'].includes(screenClass)) {
      return styles.nonBackgroundColor;
    }
    return null;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div
      className={classNames(
        styles.content,
        open,
        handleLocationBackgroundColor(),
        location.pathname === '/sei-dabei-rs'
      )}
    >
      {/* Desktop preview */}
      <Visible md lg xl xxl>
        {/* Lottie content */}
        <Background />

        <HeaderDesktopLokalac navData={!hideHeaderMenuItems ? navData : []} />
      </Visible>

      {/* Mobile preview */}
      <Visible xs sm>
        <HeaderMobileLokalac navData={!hideHeaderMenuItems ? navData : []} />
      </Visible>

      <Route path={path}>
        <main>{component}</main>
      </Route>
    </div>
  );
};

export default LayoutLokalac;
