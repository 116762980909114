// const development = {
//   url: '',
// };

// const production = {
//   url: '',
// };

// export default process.env.NODE_ENV === 'production' ? production : development;

export const USER_MAN_HOSTNAME = 'https://user-management.panex.cloud';

// Validate token parameters
const KEYCLOAK_URL = 'https://auth.panex.cloud';
const KEYCLOAK_PORT = '';
const KEYCLOAK_REALM = 'Lokavor';

export const OPEN_ID_URL = `${KEYCLOAK_URL}:${KEYCLOAK_PORT}/auth/realms/${KEYCLOAK_REALM}/protocol/openid-connect`;

export const validateAccessTokenEndPoint = `${OPEN_ID_URL}/token`;

export const configKeycloak = {
  issuer: 'https://auth.panex.cloud/auth/realms/Lokavor',
  clientId: 'lokavor-backend',
  redirectUrl: 'https://lokavor-shop.panex.cloud/*',
  scopes: ['email', 'profile', 'roles', 'web-origins'],
};
