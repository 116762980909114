import React, { useEffect, useRef, useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenModalAction } from '../../store/modal';
import { createSubscriptionAction, payLokavorProductsViaInvoiceAction } from '../../store/subscription/actions';
import { Button } from '../../components/button';
import { AppState } from '../../store/rootReducer';

const PaymentForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false); // Step 1: Add a new state variable
  const { status } = useSelector((state: AppState) => state.subscription);

  const formRef = useRef<HTMLFormElement>(null);

  const handleClick = () => {
    const form = formRef.current; // Get the current form reference
    if (form) {
      // Check if the form is not null
      formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    } else {
      // Handle the case where form is null if necessary
      console.error('Form reference is null');
    }
  };

  useEffect(() => {
    const isOperationPending = status === 'loading';
    setIsLoading(isOperationPending);
  }, [status]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.log('Stripe has not loaded');
      return;
    }
    setIsLoading(true); // Step 2: Set loading to true before processing payment

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Add any additional confirmation parameters here
        return_url: 'https://app.lokavor.de/dashboard',
      },
      redirect: 'if_required',
    });
    dispatch(createSubscriptionAction());

    if (result.error) {
      // Handle error here
      console.error(result.error.message);
      dispatch(
        setOpenModalAction({
          open: true,
          source: 'subscriptionCheckout',
          message: result.error.message,
        })
      );
    }
    setIsLoading(false);
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        disabled={!stripe || isLoading}
        isLoading={isLoading}
        callbackOnClick={handleClick}
        label="Submit Payment"
      />
    </form>
  );
};

export default PaymentForm;
