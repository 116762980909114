import { combineReducers, Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { authSlice } from './auth';

import { lottieSlice } from './lottie';
import { modalSlice } from './modal';
import { userSlice } from './user';
import { subscriptionSlice } from './subscription';
import { shopSlice } from './shop';

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  lottie: lottieSlice.reducer,
  modal: modalSlice.reducer,
  user: userSlice.reducer,
  subscription: subscriptionSlice.reducer,
  shop: shopSlice.reducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export type ThunkResult<R> = ThunkAction<R, AppState, undefined, Action>;

export default rootReducer;
