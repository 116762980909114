import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import { Col, useScreenClass } from 'react-grid-system';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import styles from './styles.module.scss';
import '../../assets/styles/css-animations.css';

import { Button } from '../../components/button';

import { IAddressDto, IShopRegistration, IShopRegistrationForm } from '../../models/shop';
import { AppState } from '../../store/rootReducer';
import { ShopTypes } from '../../enums/shop';
import { PopUp } from '../../components/modal';
import { setOpenModalAction } from '../../store/modal';
import { AddressLocationOnMap } from '../AddressLocationOnMap';
import { IGeocodeAddress } from '../../services/locationService';
import { CountryInfo } from '../../enums/country';
import { setCurrencyAction } from '../../store/subscription';

const schema = yup.object().shape({
  name: yup.string().required(),
  address: yup.string().required(),
  city: yup.string().required(),
  postalcode: yup.string().required(),
  phone: yup.string().notRequired(),
  website: yup.string().notRequired(),
  primaryShopType: yup.string().required(),
  secondaryShopTypes: yup.string().notRequired(),
});

const popUpPrimaryButtonText = (component: string | undefined) => {
  if (component === 'ADDRESS_VERIFICATION') return 'Weiter';
  if (component === 'CURRENCY_COUNTRY_VALIDATION_STEP_1') return 'Währungsraum wechseln';
  if (component === 'CURRENCY_COUNTRY_VALIDATION_STEP_2') return 'Ja';

  return 'Ok';
};

const popUpSecondaryButtonText = (component: string | undefined) => {
  if (component === 'CURRENCY_COUNTRY_VALIDATION_STEP_1') return 'Adresse ändern';
  if (component === 'CURRENCY_COUNTRY_VALIDATION_STEP_2') return 'Nein';

  return 'Zurück';
};

interface IForm {
  callbackSetButtonSelection: (value: number) => void;
  buttonSelectionActive: number | undefined;
  isButtonLoading?: boolean;
  callbackOnSubmit: (value: IShopRegistration) => void;
}

const ShopRegistrationForm: React.FC<IForm> = ({
  isButtonLoading,
  callbackSetButtonSelection,
  buttonSelectionActive,
  callbackOnSubmit,
}: IForm) => {
  const dispatch = useDispatch();
  const screenClass = useScreenClass();
  const { currency } = useSelector((state: AppState) => state.subscription);

  const { shopRegistration } = useSelector((state: AppState) => state.shop);
  const { open, source, component, message, title } = useSelector((state: AppState) => state.modal);

  const [isAddressVerified, setIsAddresVerified] = useState(false);
  const [shopPosition, setShopPosition] = useState<IGeocodeAddress>({
    location: {
      lat: 0,
      lng: 0,
    },
    country: '',
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<IShopRegistrationForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IShopRegistrationForm) => {
    if (!isAddressVerified) {
      setIsAddresVerified(true);
      dispatch(setOpenModalAction({ open: true, source: 'shopRegistrationForm', component: 'ADDRESS_VERIFICATION' }));
    } else {
      const { primaryShopType, secondaryShopTypes, address, postalcode, city, ...formValues } = data;

      const { country, location } = shopPosition;

      const addressDto: IAddressDto = {
        street: data.address,
        city: data.city,
        postalCode: data.postalcode,
        latitude: location.lat,
        longitude: location.lng,
        country,
        ...CountryInfo[country.toLowerCase() as keyof typeof CountryInfo],
      };

      const values: IShopRegistration = {
        ...formValues,
        ...shopRegistration,
        addressDto,
      };

      callbackOnSubmit(values);

      dispatch(setOpenModalAction({ open: false, source: '' }));
    }
  };

  useEffect(() => {
    if (shopRegistration.primaryShopType !== '') {
      setValue('primaryShopType', ShopTypes[shopRegistration.primaryShopType as keyof typeof ShopTypes]);

      clearErrors('primaryShopType');
    }

    if (shopRegistration.secondaryShopTypes.length > 0) {
      let secondaryShopTypes = '';

      shopRegistration.secondaryShopTypes.forEach((type: string) => {
        secondaryShopTypes += `${ShopTypes[type as keyof typeof ShopTypes]}, `;
      });

      setValue('secondaryShopTypes', secondaryShopTypes.substring(0, secondaryShopTypes.length - 2));
    }
  }, [shopRegistration, setValue, clearErrors]);

  const handlePopUpPrimaryButton = () => {
    const { country } = shopPosition;

    if (component === 'CURRENCY_COUNTRY_VALIDATION_STEP_1') {
      dispatch(
        setOpenModalAction({
          open: true,
          source: 'shopRegistrationForm',
          component: 'CURRENCY_COUNTRY_VALIDATION_STEP_2',
          message: `Bist Du sicher, dass Du die Währung auf ${currency === 'EUR' ? 'CHF' : 'EUR'}`,
          title: 'Der angegebene Firmensitz lieght nicht im zuvor gewählten Währungsraum',
        })
      );
    } else if (component === 'CURRENCY_COUNTRY_VALIDATION_STEP_2') {
      if (currency === 'EUR') dispatch(setCurrencyAction('CHF'));
      else dispatch(setCurrencyAction('EUR'));

      handleSubmit(onSubmit)();
    } else if ((country === 'Switzerland' && currency === 'EUR') || (country !== 'Switzerland' && currency === 'CHF')) {
      dispatch(
        setOpenModalAction({
          open: true,
          source: 'shopRegistrationForm',
          component: 'CURRENCY_COUNTRY_VALIDATION_STEP_1',
          message: 'Möchtest Du die Adresse des Firmensitzes andern odenden Währungsraum wechseln?',
          title: 'Der angegebene Firmensitz lieght nicht im zuvor gewählten Währungsraum',
        })
      );
    } else {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <>
      <form>
        <Col md={12} sm={12} xs={12}>
          <p className={styles.errorMessage} />

          <input {...register('name')} placeholder="Firmenname *" className={errors.name && styles.error} />

          <p className={styles.errorMessage} />

          <input
            {...register('address')}
            placeholder="Strasse, Hausnummer *"
            className={errors.address && styles.error}
          />

          <p className={styles.errorMessage} />

          <input {...register('city')} placeholder="Ort *" className={errors.city && styles.error} />

          <p className={styles.errorMessage} />

          <input
            {...register('postalcode')}
            placeholder="Postleitzahl *"
            className={errors.postalcode && styles.error}
          />
          <p className={styles.errorMessage} />

          <input {...register('phone')} placeholder="Telefon (optional)" className={errors.phone && styles.error} />

          <p className={styles.errorMessage} />

          <input {...register('website')} placeholder="Website (optional)" className={errors.website && styles.error} />

          <p className={styles.errorMessage} />

          <input
            {...register('primaryShopType')}
            placeholder="Hauptkategorie *"
            className={classNames(
              errors.primaryShopType && styles.error,
              styles.buttonSelection,
              buttonSelectionActive === 1 && styles.active
            )}
            defaultValue={getValues().primaryShopType}
            readOnly
            onClick={() => callbackSetButtonSelection(1)}
            disabled={buttonSelectionActive !== undefined}
          />
          <p className={styles.errorMessage} />

          <input
            {...register('secondaryShopTypes')}
            placeholder="Unterkategorien (optional)"
            defaultValue={getValues().secondaryShopTypes}
            className={classNames(
              errors.website && styles.error,
              styles.buttonSelection,
              buttonSelectionActive === 2 && styles.active
            )}
            readOnly
            onClick={() => callbackSetButtonSelection(2)}
            disabled={buttonSelectionActive !== undefined}
          />

          <p className={styles.errorMessage} />

          <input
            placeholder="Öffnungszeiten (optional)"
            className={classNames(
              errors.website && styles.error,
              styles.buttonSelection,
              buttonSelectionActive === 3 && styles.active
            )}
            readOnly
            onClick={() => callbackSetButtonSelection(3)}
            disabled={buttonSelectionActive !== undefined}
          />
        </Col>

        <PopUp
          title={title}
          buttonsColumnPosition={['xs', 'sm'].includes(screenClass)}
          customStylesModal={component === 'ADDRESS_VERIFICATION' ? styles.customStylesModal : undefined}
          secondaryButtonText={popUpSecondaryButtonText(component)}
          isSecondaryButton={component !== ''}
          open={open && source === 'shopRegistrationForm'}
          handleSecondaryButton={() => {
            setIsAddresVerified(false);

            dispatch(setOpenModalAction({ open: false, component, source: '' }));
          }}
          modalContent={
            <div className={styles.registrationPopUp}>
              {component === 'ADDRESS_VERIFICATION' ? (
                <AddressLocationOnMap
                  address={`${getValues().address} ${getValues().city} ${getValues().postalcode}`}
                  callbackSetShopPosition={(value) => setShopPosition(value)}
                />
              ) : (
                message
              )}
            </div>
          }
          primaryButtonText={popUpPrimaryButtonText(component)}
          handlePrimaryButton={handlePopUpPrimaryButton}
        />
      </form>

      <Col md={12} sm={12} className={styles.buttonContent}>
        <span>Pflichtfelder*</span>

        <Button
          isLoading={isButtonLoading}
          callbackOnClick={handleSubmit(onSubmit)}
          label="Weiter"
          errors={Object.keys(errors).length > 0 || !isAddressVerified}
        />
      </Col>
    </>
  );
};

export default ShopRegistrationForm;
