import axios from 'axios';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { IShopRegistration, ShopTypesResponse } from '../../models/shop';

import httpClient from '../../core/axios';
import { createShopConfig, deleteShopConfig, shopTypesConfig } from './routes';

// GET SHOP TYPES
export const getShopTypesAction = createAsyncThunk('user/getShopTypesAction', async () => {
  const shopTypes: ShopTypesResponse = await httpClient(shopTypesConfig());

  return shopTypes.data;
});

// CREATE SHOP
export const createShop = createAsyncThunk(
  'shop/createShopAction',
  async (submitData: IShopRegistration, { rejectWithValue }) => {
    try {
      const createShopResponse = await httpClient(createShopConfig(), submitData);

      return createShopResponse.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) return rejectWithValue(error.response.data.exceptionType);

      return Promise.reject();
    }
  }
);

// DELETE SHOP
export const deleteShop = createAsyncThunk('shop/deleteShopAction', async (shopId: number) => {
  try {
    const createShopResponse = await httpClient(deleteShopConfig(shopId));

    return createShopResponse.data;
  } catch (error) {
    return Promise.reject(error);
  }
});
