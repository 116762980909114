export enum ErrorTypesLokalac {
  USER_NOT_FOUND = 'Nepoznata adresa e-pošte',
  AUTHORIZATION_FAILED = 'Netacna lozinka',
  USER_LOCKED = 'Pristup je privremeno blokiran. Pričekajte 5 minuta i pokušajte ponovo.',
  VALIDATION_FAILED = 'Imajte na umu naše minimalne zahtjeve za lozinke: najmanje jedno veliko slovo, jedan broj i šest znakova.',
  shop_type_already_exists_on_address = 'Trgovina ove kategorije već postoji u našoj bazi podataka na ovoj adresi.',
  shop_name_already_exists_on_address = 'Trgovina s ovim nazivom već postoji u našoj bazi podataka na ovoj adresi.',
  stripe_combine_currencies_failed_already_chf = 'Ups! Ova pretplata je rezervirana u CHF i stoga se može produžiti samo u CHF. Molimo prilagodite valutu u skladu s tim.',
  stripe_combine_currencies_failed_already_eur = 'Ups! Ova pretplata je rezervirana u eurima i stoga se može produžiti samo u eurima. Molimo prilagodite valutu u skladu s tim.',
}

export const ErrorCodeTypesLokalac = {
  409: 'Korisnik s ovom e-mail adresom već postoji.',
};
