import React from 'react';
import { appleStoreBadge, dashboardGroupImage, googlePlayBadge } from '../../assets/images';
import styles from './styles.module.scss';

const Download: React.FC = () => {
  return (
    <div className={styles.content}>
      <img src={dashboardGroupImage} alt="dashboard_image" />

      <div className={styles.container}>
        <span>LOCAL FOOD GOES MOBILE</span>
        <span>Die neue App für lokale Lebensmmittelanbieter</span>

        <div className={styles.badgeContainer}>
          <a href="https://play.google.com/store/apps/details?id=com.lokavor" target="_blank" rel="noreferrer">
            <img src={googlePlayBadge} alt="google-play" />
          </a>
          <a href="https://apps.apple.com/us/app/lokavor/id1517590894" target="_blank" rel="noreferrer">
            <img src={appleStoreBadge} alt="apple-store" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Download;
