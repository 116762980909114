export enum ShopTypes {
  JUICE_PRESSING = 'Mosterei',
  VINTNER = 'Winzer/Weinhandel',
  BUTCHER = 'Metzgerei',
  FARMERS_SHOP = 'Hofladen',
  GREENGROCER = 'Obst / Gemüsehändler',
  FISHER = 'Fischerei / Fischzucht',
  BREWERY = 'Brauerei',
  FARM_COOPERATIVE = 'Genossenschaft',
  DISTILLERY = 'Brennerei',
  ORGANIC_SUPERMARKET = 'Biosupermarkt',
  SUPERMARKET = 'Supermarkt',
  HEALTH_FOOD_STORE = 'Reformhaus',
  DELIVERY_SERVICE = 'Abo-Kisten / Lieferservice',
  BAKER = 'Bäckerei',
  BEE_KEEPER = 'Imkerei',
  DAIRY = 'Molkerei',
  SPICE_TRADE = 'Gewürzhandel',
  GASTRONOMY = 'Gastronomie',
  CHEESE_DAIRY = 'Käserei',
  DELICATESSE = 'Feinkost',
  DRINKS_SHOP = 'Getränkemarkt',
  GAME_MEAT = 'Wild',
  FARMERS_MARKET = 'Wochenmarkt',
  CROPS = 'Nutzpflanzen',
  GOURMET_FOOD = 'Nutzpflanzen',
  POULTRY_AND_EGGS = 'Geflügel und eier',
  HANDICRAFTS = 'Handwerk',
  NATURAL_COSMETICS = 'Natürliche kosmetik',
  MEDICINAL_HERBS_AND_TEAS = 'Medizinische kräuter und tees',
}
