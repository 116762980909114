import React from 'react';
import { appleStoreBadge, dashboardGroupImage, googlePlayBadge } from '../../assets/images';
import styles from './styles.module.scss';

const TermsLokalacBih: React.FC = () => {
  return (
    <div className={styles.content}>
      <div className={styles.title}>
        <span>Pravila i uslovi korišćenja Lokalac app</span>
      </div>
      <div className={styles.paragraph}>
        <p>Molimo Vas da pažljivo pročitate Pravila i uslove korišćenja aplikacije Lokalac (Pravilnik). </p>
        <br />
        <p>
          Dobrovoljnim korišćenjem aplikacije Lokalac, podrazumijeva se da ste saglasni sa Pravilnikom, te da u
          potpunosti prihvatate isti.
        </p>
        <p>
          Pravilnik je podložan promjenama. Lokalac zadržava pravo izmjene sadržaja Pravilnika, bez prethodne najave.
          Iste stupaju na snagu trenutkom objave na stranicama ove aplikacije i kao takve se odnose na sve dosadašnje i
          buduće oglašivače i pratioce ( u daljem tekstu Korisnike). U slučaju značajnih izmjena Lokalac zadržava pravo
          da od korisnika zatraži ponovno prihvatanje istog.
        </p>
        <br />
        <p>
          Za vrijeme korišćenja aplikacije Lokalac, primjenjuju se svi zakoni i propisi koji važe na teritoriji Bosne i
          Hercegovine
        </p>
        <br />
        <br />
        <span>Član 1 Definisanje i opis usluga </span>
        <br />
        <br />
        <p>
          Mobilnu Aplikaciju Lokalac treba shvatiti kao virtuelni oglasni prostor u isključivom vlasništvu kompanije
          Lokalac doo.
        </p>
        <p>Naziv kompanije: Lokalac doo</p>
        <p>Sjedište: Ivana Senkovića 32, 21203 Veternik, Republika Srbija</p>
        <p>MB: 21925098</p>
        <p>PIB: 113783212</p>
        <br />
        <p>
          Lokalac pruža usluge internet oglasavanja u skladu sa članom 45 Zakona o oglašavanju uz uslov prihvatanja
          Pravilnika u svojstvu ugovora: a) kreiranje naloga oglašivača za svoje ime i za svoj račun i
          postavljanje/uklanjanje njihovih proizvoda za prodaju zajedno sa slikom ili drugim video materijalom, cenom (
          jasno i nedovosmisleno naznačenom ) i opisom sa ciljem veće informisanosti i posećenosti zarad realizacije
          kupoprodaje prozvoda; b) registrovanja pratilaca kao potencijalnih kupaca ; c) mogućnost davanja ocena na dati
          proizvod u vidu zvezdica ; d) kreiranje porudžbine u svoje ime od strane pratilaca; e) direktno pokretanje
          drugih aplikacija za komunkaciju.
        </p>
        <br />
        <p>
          Korisnici su sami odgovorni za tačnost, istinitost, verodostojnost, kao i uopštenu zakonitost svih navoda iz
          postavljenog oglasa. Shodno tome, sve objave, proizvodi, tekstovi, fotografije i drugi materijali, objavljeni
          putem aplikacije, preneseni i/ili linkovani sa web sajta su isključiva odgovornost registrovanog oglašivača
          aplikcije iz čijeg oglasa ti podaci potiču.
        </p>
        <br />
        <p>
          Lokalac, ne nudi prodaju, zamenu ili bilo koju drugu uslugu iz oglasa koji su objavljeni putem aplikacije, već
          to čine isključivo i samo registrovani oglašivač aplikacija Lokalac. Korisnik aplikacije Lokalac je kreator,
          oglašivač i vlasnik sadržaja koji objavljuje na ovoj aplikaciji. Odgovorn je za podatke koji su navedeni na
          stranici, proizvodima/oglasima i za kreirane porudžbine, kao i za dalju komunikaciju koja se odvija radi
          realizacije, a putem drugih ponudjenih kanala komunikacije.
        </p>
        <br />
        <span>Član 2 Registracija i oglašavanje od strane Korisnika usluga </span>
        <br />
        <br />
        <p>
          Da bi koristili usluge Lokalac neophodna je registracija Korisnika (otvaranje korisničkog naloga), koja je
          besplatna. Registracija se obavlja prihvatanjem Pravinika u svojstvu ugovora, nakon čega Korisniku stiže
          potvrda o registraciji putem elektronske pošte.
        </p>
        <p>
          Kreirati nalog kao oglašivač/prodavac i oglašavati proizvode za prodaju mogu oni subjekti koji su registovani
          kao takvi za datu djelatnost.
        </p>
        <br />
        <p>
          Registrovani oglašivač ima mogućnost da priloži sve potrebne dozvole, licence i sertifikate za prodaju
          određenih proizvoda. Oglašivač ima mogućnost da doda i video sadržaj, linkove, slike i sl
        </p>
        <br />
        <p>
          Postavljanjem oglasa i prihvatanjem Pravilnika kao ugovora oglašivač potvrđuje da poseduje prava, potrebne
          licence i dozvole za oglašavanje i objavu proizvoda. Lokalac nema odgovornost u slučaju neposedovanja istih od
          strane oglašivača.
        </p>
        <br />
        <p>
          Mogu kreirati nalog i drugi subjekti koji oglašavaju proizvode koji su dozvoljeni za oglašavanje ovim
          Pravilnikom.
        </p>
        <br />
        <p>
          Prihvatanjem ovog Pravilnika, podrazumeva se da svako fizičko lice koje pristupi aplikaciji ima odgovarajuću
          poslovnu sposobnost neophodnu za zaključenje ugovora sa Lokalac doo. Lokalac nije dužan da ispituje poslovnu
          sposobnost Korisnika aplikacije, te se isključuje odgovornost u slučaju eventualne štete koja može nastati
          korišćenjem iste od strane poslovno nesposobnog lica.
        </p>
        <br />
        <p>
          Registrovani i ulogovani oglašivač može postavljati oglase za prodaju proizvoda na svoj nalog zajedno sa
          opisom proizvoda i slikom.
        </p>
        <br />
        <p>
          Registrovani i ulogovani pratilac može kreirati poridžbinu, davati ocene proizvoda u vidu zvezdica, kao i
          pokrenuti druge ponuđene aplikacije ili servis za komunukaciju preko Lokalac aplikacije da bi stupio u kontakt
          sa oglašivačem.
        </p>
        <br />
        <p>
          Lokalac nije odgovoran za slučaj nesavesnog postupanja Korisnika. Stoga se svi Korisnici pozivaju da deluju u
          skladu sa ciljem aplikacije. U slučaju suprotnog, Lokalac može po diskrecionoj oceni da blokira nesavesnog
          Korisnika uz prethodno upozorenje i obaveštenje putem maila.
        </p>
        <br />
        <p>
          Oglašivač / prodavac može imati samo jedan registrovani korisnički nalog. Sa istim nalogom Korisnik može biti
          i oglašivač i pratilac. Oglašivač / prodavac prihvata da će sadržaj i svi podaci cjelokupnog oglasa, odnosno
          tekst oglasa zajedno sa fotografijama, kao i kontakt telefon ili e-mail, biti objavljeni na mobilnoj
          aplikaciji Lokalac, te da će biti javno dostupni svim korisnicima interneta. Registrovani oglašivač prihvata
          mogućnost kontakta na broj telefona ili e-mail ( ukoliko je taj podatak ostavljen kao opcija ). Oglašivač
          pristaje da javno dostupni podaci iz oglasa mogu bez naknade po potrebi biti korišćeni u svrhu reklamiranja i
          promotivnih aktivnosti aplikacije Lokalac. Oglasi koje postavljaju oglašivači / prodavci moraju u svemu biti u
          skladu sa važećim zakonima Bosne i Hercegovine. U suprotnom će biti uklonjeni od strane administratora
          Lokalac, uz upozorenje i obaveštenje.
        </p>
        <br />
        <p>
          Oglašivači se pozivaju na savesno ažuriranje naloga. Lokalac može ukloniti korisnički nalog, oglase ili sve
          podatke o proizvodu ukoliko utvrdi da postoje opravdani razlozi za uklanjanje istog, što se utvrdjuje u svakoj
          pojedinačnoj situaciji ( pratioci informišu da je kontakt nedostupan, duži vremenski period nije snabdeven
          oglašenim proizvodom i sl.)
        </p>
        <br />
        <p>
          Korisničko ime može biti promenjeno, ukoliko Lokalac smatra da je promena potrebna, bez odgovornosti prema
          oglašavaču, a uz prethodno obaveštenje.
        </p>
        <br />
        <p>
          Oglašivač pristaje na umetanje vodenog žiga po potrebi od strane Lokalca u svaku od fotografija koju priloži
          prilikom postavljanja oglasa. Ako oglašivači nisu u saglasnosti sa tim ( nakon prestanka korišćenja aplikacije
          npr. ) obavezuju se da obaveste administraciju Lokalaca.
        </p>
        <br />
        <br />
        <span>Član 3. Trgovina između Korisnika usluga</span>
        <br />
        <br />
        <p>
          Lokalac ne posreduje u trgovini između Korisnika aplikacije. Svaka trgovina ili razmena između pomenutih
          subjekata je na sopstveni rizik. Lokalac doo, kao vlasnik aplikacije i Lokalac neće snositi bilo koju
          odgovornost za eventualnu nanetu štetu koja je nastala prilikom bilo kakvih transakcija između Korisnika
          aplikacije. Stoga se Korisnici pozivaju na oprez i savest prilikom međusobne trgovine.
        </p>
        <br />
        <p>
          Objavom oglasa na Lokalacu oglašivač stupa u svojstvo ponuditelja proizvoda shodno Zakonu o obligacionim
          odnosima, te samim tim samostalno odgovara za bitne elemente u pogledu predmeta i cjene proizvoda koji se
          nudi. Takodje, potvrđuje i odgovara za istinitost, vjerodostojnost podataka i navoda iz opisa proizvoda i
          shodno tome snosi svu odgovornost, kako krivičnu, tako i građansku zbog davanja lažnih podataka, odnosno
          oglašavanja proizvoda suprotno propisima Bosne i Hercegovine.
        </p>
        <br />
        <br />
        <span>Član 4. Ograničenja i nedozvoljene radnje prilikom korišćenja Lokalca</span>
        <br />
        <br />
        <p>Korisnici usluga su saglasni da će prilikom korišćenja Lokalca poštovati sledeća ograničenja:</p>
        <br />
        <ol style={{ listStyleType: 'disc' }}>
          <li>
            Nije dozvoljeno postavljati oglase koji su na bilo kakav način u suprotnosti sa odredbama važećih zakona na
            teritoriji Bosne i Hercegovine.
          </li>
          <li>
            Nije dozvoljeno postavljati oglase koji su u suprotnosti sa bilo kojom odredbom Zakona o bezbednosti hrane
            (“Sl glasnik RS” , br. 41/2009 I 117/2019 )
          </li>
          <li>
            Nije dozvoljeno postavljati oglase koji sadrže neistine i koji mogu dovesti bilo koga u zabludu koristeći
            njihovu lakoverenost i lakomislenost, te na taj način naneti nekome štetu
          </li>
          <li>
            Nije dozvoljeno postavljati oglase koji vređaju bilo koga po bilo kom osnovu i koji podržavaju
            diskriminaciju.
          </li>
          <li>
            Nije dozvoljeno postavljanje oglasa čiji predmet oglašavanja sadrži tuđe lično ime i prezime, osim u slučaju
            da za to oglašivač ima pribavljenu izričitu saglasnost nosioca tog ličnog imena i prezimena.
          </li>
          <li>
            Nije dozvoljeno postavljati oglase kojima se oglašavaju: piratske kopije proizvoda, internet nalozi,
            digitalna distribucija i nabavka igara i softvera za fizička lica i druga virtualna dobra (kriptovalute i
            sl.), pirotehnička sredstva, opojne droge i njihova upotreba, seksualna pomagala, oružje i municija,
            uključujući kopije, startne pištolje i vazdušno oružje,
          </li>
          <li>
            Nije dozvoljeno postavljati oglase koji sadrže duvan i duvanske proizvode (uključujući i elektronske
            cigarete) kao i promovisati njihovo konzumiranje
          </li>
          <li>
            Nije dozvoljeno kreiranje oglasa alkoholnih pića koja nisu u skladu sa ograničenjima oglašavanja alkoholnih
            pića
          </li>
          <li>Nije dozvoljeno promovisanje medicinsko-alternativnih postupaka i metoda kao i nadrilekarstva</li>
          <li>Nije dozvoljeno oglašavanje lekova </li>
          <li>Nije dozvoljeno postavljati oglase sa bilo kakvim političkim sadržajem.</li>
          <li>Nije dozvoljeno oglašavanje prostitucije i pornografskih sadržaja, kao i igara na sreću.</li>
          <li>Nije dozvoljeno oglašavanje preprodaje i kupovine ulaznica za bioskope, sportske i druge priredbe.</li>
          <li>Nije dozvoljeno oglašavanje u formi javnog obraćanja ili proglasa.</li>
          <li>
            Nije dozvoljeno postavljati oglase u kojima se nudi upoznavanje ili posredovanje u upoznavanju radi
            sklapanja braka ili emotivne veze.
          </li>
          <li>Nije dozvoljeno oglašavanje sredstava za samoodbranu </li>
          <li>
            Nije dozvoljeno postavljati oglase kojima se oglašavaju različite vrste korišćenih ili prerađenih ulja zbog
            negativnog uticaja na životnu sredinu.
          </li>
          <li>
            Nije dozvoljeno oglašavati bilo koje proizvode koji opštepoznato ili prema deklaraciji mogu trajno zagaditi
            životnu sredinu.
          </li>
          <li>
            Nije dozvoljeno izazivanje, širenje i raspirivanje rasne, nacionalne, verske i polne mržnje i diskriminacije
          </li>
          <li>Nije dozvoljeno neovlašteno predstavljanje u ime administracije Lokalac</li>
          <li>
            Nije dozvoljeno postavljati druge, već isključivo slike proizvoda koji se oglašavaju, koje se prethodno mogu
            obraditi
          </li>
          <li>
            Nije dozvoljeno postavljanje fotografija koje sadrže grafičku obradu radi posebnog isticanja, ili sadrže
            logotip firmi.
          </li>
          <li>
            Nije dozvoljeno prikazati lica osoba i detalje na fotografijama koji mogu dovesti do identifikacije osoba
          </li>
          <li>Nije dozvoljeno namerno postavljanje oglasa u pogrešnim tj. neadekvatnim kategorijama</li>
          <li>Nije dozvoljeno postavljati oglase koji su u bilo kom smislu uvredljivi prema aplikaciji Lokalac </li>
          <li>Nije dozvoljeno postavljanje oglasa kojim se oglašava prodaja zakonom zaštićenih životinja</li>
          <li>
            Nije dozvoljeno kreiranje oglasa koji nisu u skladu sa posebnim pravilima o zaštiti dece i maloletnika od
            neprikladnog oglašavanja
          </li>
          <li>
            Nije dozvoljeno kreirati oglas koji bi bio u suprotnosti sa upotrebom državnih simbola Bosne i Hercegovine,
            stranih država i simbola medjunarordnih organizacija
          </li>
          <li>
            Nije dozvoljeno u oglasu isticanje i iznošenje neistinitih tvrdnji (npr. isticanjem reči &quot;ekološki
            siguran&quot;, &quot;ekološki neškodljiv&quot;, &quot;eko hrana&quot;, &quot;organski proizvod&quot; i
            sličnih reči ili simbola) da roba ili usluga imaju pozitivan ili neškodljiv uticaj na zaštitu zdravlja ili
            životne sredine (Zakon o oglašavanju Član 10)
          </li>
        </ol>
        <br />
        <br />
        <span>Član 5. Isključenje odgovornosti</span>
        <br />
        <br />
        <p>
          Shodno odredbama Zakona o elektronskom i pravnom i poslovnom prometu (sl. glasnik BiH br. 88/2007), Lokalac ne
          snosi nikakvu odgovornost za informacije unesene od strane Korisnika ove aplikacije, a kojim informacijama
          Lokalac doo samo osigurava pristup.
        </p>
        <br />
        <p>
          Shodno odredbama Zakona o elektronskom i pravnom i poslovnom prometu (sl. glasnik BiH br. 88/2007), Lokalac ne
          snosi nikakvu odgovornost za informacije unesene od strane Korisnika ove aplikacije, a kojim informacijama
          Lokalac doo samo osigurava pristup.
        </p>
        <br />
        <p>
          Lokalac nije odgovoran za sadržaj elektronskih poruka koje korisnici međusobno razmjenjuju koristeći druge
          alate za komunikaciju ( sms ili viber ) iako su komunikaciju pokrenuli kroz aplikaciju Lokalac.
        </p>
        <br />
        <p>Lokalac nije odgovoran za sadržaj podataka skladištenih u bazi podataka i/ili objavljenih na Lokalacu.</p>
        <br />
        <p>
          Lokalac nije odgovoran za sadržaj na drugim portalima u slučaju kada oglašivači u svojim oglasima ili porukama
          umeću linkove koji vode ka tim drugim portalima.
        </p>
        <br />
        <p>
          Lokalac nije dužan da pregleda podatke koje je skladištio, učinio dostupnim ili prenio, tj. da istražuje
          okolnosti koje bi upućivale na nedopušteno djelovanje Korisnika datih usluga.
        </p>
        <br />
        <p>
          Lokalac isključuje svoju odgovornost ukoliko je sadržaj oglasa protivan odredbama važećih zakona i Pravilnika.
        </p>
        <br />
        <p>
          Lokalac, administrator koji održavaju aplikaciju kao i vlasnici nisu odgovorni za štete koje mogu nastupiti
          radi grešaka koje su moguće prilikom automatske ili ručne obrade podataka ili prikaza istih.
        </p>
        <br />
        <p>
          Lokalac ne garantuje legalnost, sigurnost ni kvalitet ponudjenih proizvoda od strane oglašivača. Takodje, ne
          garantuje ni za tačnost i istinitost tekstova, kao i ličinih podataka koji su postavljeni od strane
          oglašivača.
        </p>
        <br />
        <p>
          Lokalac ne garantuje da na stranicama neće biti grešaka i/ili da će funkcionisati bez prekida u svakom
          trenutku.
        </p>
        <br />
        <p>
          Lokalac povremeno može biti nedostupan svima ili delu Korisnika u toku odredjenih vremenskih perioda zbog
          tehničkih poteškoća ili zbog delovanja više sile. U takvim slučajevima vlasnik aplikacije, kao i Lokalac ne
          odgovara za eventualnu štetu nastalu zbog prestanka emitovanja oglasa.
        </p>
        <br />
        <p>
          Lokalac može sadržavati linkove prema drugim stranicama, ali ne odgovara za usluge ili proizvode do kojih se
          došlo pomoću Lokalaca na taj način.
        </p>
        <br />
        <p>
          Lokalac zadržava pravo da odbije objavljivanje oglasa ukoliko je isti protivan zakonima, javnom poretku,
          dobrim poslovnim običajima i moralu, te u tom slučaju ne odgovara za eventualnu nastalu štetu ovakvim
          činjenjem.
        </p>
        <br />
        <span>Član 6. Zaštita autorskih prava i zabrana neovlašćenog korišćenja sadržaja objavljenih na Lokalac</span>
        <br />
        <br />
        <p>
          Lokalac doo ima isključiva autorska prava na aplikaciju Lokalac, kao i na pojedinim elementima koji ih čine,
          kao što su tekst, vizuelni i audio elementi, vizuelni identitet, dizajin, podaci i baze podataka, programski
          kod, logo i drugi elementi servisa. Iskorišćavanje pomenutih predmeta autorskog prava, bez izričite prethodne
          pisane saglasnosti Lokalac doo smatraće se povredom autorskog prava, te će Lokalac doo u tom slučaju biti
          ovlašćena da iskoristi sva dostupna pravna sredstva kako bi to svoje pravo zaštitila.
        </p>
        <br />
        <p>
          Lokalac sadrži i elemente na kojima isključiva autorska i druga prava iz oblasti intelektualne svojine imaju
          druga lica, kao što su sadržaji oglašivača proizvoda i njihovih poslovnih partnera. Druga lica imaju
          isključivu odgovornost za sadržaj na kojem su nosioci tih prava, bez obzira na to što se takav sadržaj nalazi
          na Lokalacu. Za zaštitu svojih prava su isključivo odgovorni oglašivači koji kreiraju oglase.
        </p>
        <br />
        <p>
          Podaci se mogu koristiti samo na način predviđen ovim Pravilnikom. Korištenjem podataka u druge svrhe, kao i
          kopiranjem, prepisom, distribuisanjem podataka, bez odobrenja, postupate protivno Zakonu o autorskom pravu i
          srodnim pravima u BiH, te podliježete sankcijama.
        </p>
        <br />
        <p>
          Lokalac doo i Lokalac se izuzimaju od odgovornosti i učešća u eventualnim sporovima u vezi s povredom i
          zaštitom autorskih prava oglašivača na Lokalacu.
        </p>
        <br />
        <span>Član 7. Prikupljanje i obrada podataka, uključujući podatke o ličnosti</span>
        <br />
        <br />
        <p>
          U svrhu oglašavanja na aplikaciji Lokalac, Korisnici se obavezuju registrovati, te tom prilikom popuniti svoje
          lične podatke, kao i podatke za kontakt. Korisnici su odgovorni i garantuju za istinitost i tačnost popunjenih
          podataka. Prilikom registracije na Lokalac Korisnici daju izvjavu da su saglasni da popunjeni lični podaci u
          postupku registracije budu uneseni u bazu ličnih podataka koju vodi adminstracija Lokalac. Korisnici
          istovremeno dopuštaju i obradu unetih ličnih podataka u svrhu delovanja sistema aplikacije, dok se Lokalac
          obavezuje da će dostavljene lične podatke koristiti u svrhu za koju su dobijeni. Radi zaštite podataka
          registrovanih korisnika Lokalac obrađuje podatke o ličnosti u skladu sa Zakonom o zaštiti ličnih podataka.
        </p>
        <br />
        <p>
          Lokalac ne odgovara za tačnost podataka koje su unela druga lica, kao ni za tačnost rezultata obrade takvih
          podataka.
        </p>
        <br />
        <p>
          Oglašivači treba da budu svesni da će određeni podaci o ličnosti, tj. oni podaci koji su neizostavni deo
          oglasa oglašenog na Lokalacu, a postavili su ih oni sami, biti dostupni drugim Korisnicima aplikacije kao i
          posetiocima, odnosno biti javno dostupni na internetu. Shodno tome Lokalac isklučuje odgovornost u slučaju
          svake zlouporebe ličnih podataka objavljenih u oglasu.
        </p>
        <br />
        <p>
          Registrovani Korisnik aplikacije prihvata mogućnost kontakta putem e-maila, SMS-a ili drugih aplikacija za
          komunikaciju od strane tima Lokalac u svrhu unapređenja i razvoja aplikacije Lokalac, kao i u promotivne ili
          marketinške svrhe.
        </p>
        <br />
        <p>
          Korisnik usluga ima pravo da opozove pristanak koji je prihvatanjem ovog Pravilnika dao za prikupljanje i
          obradu ličnih podataka tako što će podneti zahtev administraciji putem e-maila. Takodje Korisnici imaju pravo
          na izmenu ličnih podataka.
        </p>
        <br />
        <p>
          Lokalac garantuje da podatke ( user name i password ) Korisnika ne otkriva trećim licima, osim u slučajevima
          propisanim važećim zakonima naše zemlje. Lične podatke Korisnika Lokalac je ovlašćen proslediti trećim licima
          samo ukoliko postoji zahtev i/ili nalog nadležnog organa i/ili je takvo prosleđivanje propisano zakonom i/ili
          je zahtevano od strane vlasnika podataka izričitim pismenim prethodnim zahtevom.
        </p>
        <br />
        <p>
          Lokalac ne preuzima odgovornost po bilo kojem osnovu, ukoliko Korisnici sami otkriju svoje podatke trećim
          licima, davanjem korisničkog imena i šifre (user name i password) ili im te podatke učini dostupnim da drugi
          način.
        </p>
        <br />
        <span>Član 8. Kršenja Pravilnika Lokalac</span>
        <br />
        <br />
        <p>
          Za kršenje Pravilnika prilikom korišćenja Lokalaca administrator ima pravo slanja upozorenja Korisnicima uz
          napomenu mogućnosti privremene ili trajne blokade korisničkog naloga ukoliko dodje do ponavljanja ili
          nastavljanja istog postupka. Korisnici aplikacije čiji je nalog privremeno ili trajno blokiran nemaju pravo ni
          na kakvu nadoknadu štete zbog toga što za vreme trajanja blokade naloga nisu mogli da koriste aplikaciju.
        </p>
        <br />
        <span>Član 9. Kontakt sa korisničkim timom </span>
        <br />
        <br />
        <p>
          U cilju rešavanja tehničkih poteškoća ili za druga obaveštenja Korisnici se mogu obratiti Korisničkom timu na
          e-mail.
        </p>
        <br />
        <p>
          Obraćanje može biti u slobodnoj formi i tom prilikom Korisnici mogu potaviti konkretna pitanja. Korisnički tim
          će na isti odgovoriti u što kraćem roku.
        </p>
        <br />
        <span>Član 10. Prelazne i završne odredbe</span>
        <br />
        <br />
        <p>
          Na sve sporove do kojih može doći između Lokalac doo i Korisnika u vezi sa korišćenjem aplikacije Lokalac kao
          i Korisnika međusobno, primenjuju se važeći propisi Bosne I Hercegovine. Strane se obavezuju da spor pokušaju
          da riješe mirnim putem, a ukoliko u tome ne uspiju, nadležan je sud u Banja Luci.
        </p>
        <br />
      </div>
    </div>
  );
};

export default TermsLokalacBih;
