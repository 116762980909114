import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CSSTransition } from 'react-transition-group';

import styles from '../styles.module.scss';
import { lokavorLogo, menuIcon } from '../../../../assets/images';
import { IHeaderComponent } from '../../../../models/header';

import '../../../../assets/styles/css-animations.css';
import { AppState } from '../../../../store/rootReducer';
import { logOutAction } from '../../../../store/auth';
import history from '../../../../core/history';

export const HeaderMobile: React.FC<IHeaderComponent> = ({ navData }: IHeaderComponent) => {
  const dispatch = useDispatch();
  const { isLogin } = useSelector((state: AppState) => state.auth);

  const [toggleMenu, setToggleMenu] = useState<boolean>(false);
  const [toggleNavItem, setToggleNavItem] = useState<{ open: boolean; index: undefined | number }>({
    open: false,
    index: undefined,
  });

  return (
    <div className={styles.headerContent}>
      <div className={styles.headerContainer}>
        <a href="https://www.lokavor.de/" target="_blank" rel="noreferrer">
          <img src={lokavorLogo} alt="lokavorLogo" className={styles.mobileIcon} />
        </a>

        <span
          onClick={() => {
            setToggleMenu(!toggleMenu);
            setToggleNavItem({ open: false, index: undefined });
          }}
          role="button"
          tabIndex={0}
        >
          <img src={menuIcon} alt="menuIcon" className={styles.menuIcon} />
        </span>
      </div>

      <CSSTransition in={toggleMenu} timeout={300} classNames="mobile-navigation-translate" unmountOnExit>
        <div className={styles.mobileMainNavContent}>
          {navData.map((navDataItem) => {
            return (
              <div key={navDataItem.index} className={styles.mobileMainNav}>
                <div
                  className={styles.mainNavText}
                  role="button"
                  tabIndex={0}
                  onClick={() =>
                    setToggleNavItem((previousValues) =>
                      previousValues.index === navDataItem.index
                        ? { open: false, index: undefined }
                        : { open: true, index: navDataItem.index }
                    )
                  }
                >
                  {navDataItem.name}
                </div>
                <CSSTransition
                  in={
                    toggleNavItem.open &&
                    toggleNavItem.index === navDataItem.index &&
                    Array.isArray(navDataItem.navItems)
                  }
                  timeout={200}
                  classNames="mobile-navigation-item"
                  unmountOnExit
                >
                  <div className={styles.navMobileItemsContent}>
                    {Array.isArray(navDataItem.navItems) &&
                      navDataItem.navItems.map((item) => {
                        return (
                          <a href={item.url} className={styles.navItem} key={item.key} target="_blank" rel="noreferrer">
                            {item.name}
                          </a>
                        );
                      })}
                  </div>
                </CSSTransition>
              </div>
            );
          })}

          {isLogin && (
            <div className={styles.mobileMainNav}>
              <div
                className={styles.mainNavText}
                role="button"
                tabIndex={0}
                onClick={() => {
                  dispatch(logOutAction());
                }}
              >
                Ausloggen
              </div>
            </div>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};
