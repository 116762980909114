import { IAxiosConfig } from '../../models/axios';
import { USER_MAN_HOSTNAME } from '../../core/axios/environment';

const shopService = 'https://shop-service.panex.cloud';

const payShopSubscriptionViaInvoiceEndpoint = `https://user-management.panex.cloud/subscriptions/pay-via-invoice`;
const createShopSubscriptionEndpoint = `https://user-management.panex.cloud/subscriptions`;
const createPaymentIntent = `https://user-management.panex.cloud/stripe/payment-intent`;

export const shopTypesConfig = (): IAxiosConfig => {
  return { url: `${shopService}/shoptypes`, method: 'GET' };
};

export const createShopConfig = (): IAxiosConfig => {
  return { url: `${shopService}/shops`, method: 'POST' };
};

export const createPaymentIntentConfig = (): IAxiosConfig => {
  return { url: createPaymentIntent, method: 'POST' };
};

export const deleteShopConfig = (shopId: number): IAxiosConfig => {
  return { url: `${shopService}/shops/${shopId}`, method: 'DELETE' };
};

export const payShopSubscriptionViaInvoiceConfig = {
  url: payShopSubscriptionViaInvoiceEndpoint,
  method: 'POST',
};

export const createShopSubscriptionConfig = {
  url: createShopSubscriptionEndpoint,
  method: 'POST',
};
