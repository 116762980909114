import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { Button } from '../../components/button';
import { PopUp } from '../../components/modal';
import { IShopType } from '../../models/shop';
import { setOpenModalAction } from '../../store/modal';
import { AppState } from '../../store/rootReducer';
import styles from './styles.module.scss';

interface IShopTypes {
  handleBackButton: () => void;
  maxSelectedValues: number;
  callbackHandleSubmit: (activeShopTypes: string[]) => void;
  errorMessage: string;
  name: string;
  title: string;
}

export const ShopTypes: React.FC<IShopTypes> = ({
  handleBackButton,
  maxSelectedValues,
  callbackHandleSubmit,
  errorMessage,
  name,
  title,
}: IShopTypes) => {
  const dispatch = useDispatch();

  const { shopTypes, shopRegistration } = useSelector((state: AppState) => state.shop);
  const { open, source } = useSelector((state: AppState) => state.modal);

  const [activeShopTypes, setActiveShopTypes] = useState<string[]>([]);

  useEffect(() => {
    if (name === 'primaryShopType' && shopRegistration.primaryShopType !== '') {
      setActiveShopTypes([shopRegistration.primaryShopType]);
    } else if (name === 'secondaryShopTypes' && shopRegistration.secondaryShopTypes.length > 0) {
      setActiveShopTypes(shopRegistration.secondaryShopTypes);
    }
  }, [name, shopRegistration]);

  return (
    <div className={styles.content}>
      <div className={styles.headerContainer}>{title}</div>

      <div className={styles.shopTypesContainer}>
        {shopTypes.map((type: IShopType, i) => {
          return (
            <div key={i} className={styles.shopType}>
              <CSSTransition in={activeShopTypes.includes(type.name)} timeout={500} classNames="button" unmountOnExit>
                <span
                  className={classNames(styles.animationBackground)}
                  role="button"
                  tabIndex={0}
                  aria-label={type.label}
                />
              </CSSTransition>

              <div
                role="button"
                tabIndex={0}
                className={styles.textContainer}
                onClick={() => {
                  setActiveShopTypes((indexes: string[]) => {
                    if (indexes.includes(type.name))
                      return indexes.filter((index: string) => {
                        return index !== type.name;
                      });

                    if (activeShopTypes.length < maxSelectedValues) {
                      return [...indexes, type.name];
                    }

                    dispatch(setOpenModalAction({ open: true, source: 'shopTypes' }));

                    return indexes;
                  });
                }}
              >
                {type.label}
              </div>
            </div>
          );
        })}
      </div>

      <div className={styles.buttonOHandSTContainer}>
        <Button
          callbackOnClick={() => {
            callbackHandleSubmit(activeShopTypes);

            handleBackButton();
          }}
          disabled={!(activeShopTypes.length > 0)}
          label="Weiter"
        />

        <div role="button" tabIndex={0} className={styles.backText} onClick={handleBackButton}>
          Abbrechen
        </div>
      </div>

      <PopUp
        open={open && source === 'shopTypes'}
        modalContent={<div className={styles.registrationPopUpContent}>{errorMessage}</div>}
        primaryButtonText="OK"
        handlePrimaryButton={() => dispatch(setOpenModalAction({ open: false, source: '' }))}
      />
    </div>
  );
};
