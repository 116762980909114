import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import styles from './styles.module.scss';

import {
  dashboardGroupImageLokalac,
  loginBackgroundImage,
  loginGroupImages,
  registrationBackgroundImage,
  registrationGroupImages,
  shopRegistrationBackgroundImage,
} from '../../../assets/images';

export const Background: React.FC = () => {
  const [changeLocation, setChangeLocation] = useState(false);

  useEffect(() => {
    setChangeLocation(false);
    setChangeLocation(true);
  }, []);

  const location = useLocation();

  const handleGroupImages = () => {
    switch (location.pathname) {
      case '/login':
        return (
          <div className={classNames(styles.container, changeLocation && styles.fadeIn)}>
            <img src={loginGroupImages} alt="loginGroupImages" />
          </div>
        );

      case 'new/registration':
        return (
          <div
            className={classNames(styles.container, styles.registrationPage, changeLocation && styles.fadeIn)}
            style={{ backgroundImage: registrationBackgroundImage }}
          >
            <img src={dashboardGroupImageLokalac} alt="registrationGroupImages" />
          </div>
        );

      case '/registration':
      case '/subscription/shop/registration':
        return (
          <div
            className={classNames(
              styles.container,
              styles.registrationPage,
              changeLocation && styles.fadeIn,
              location.pathname === '/subscription/shop/registration' && styles.subscription
            )}
            style={{ backgroundImage: registrationBackgroundImage }}
          >
            <img src={registrationGroupImages} alt="registrationGroupImages" />
          </div>
        );
      default:
        return null;
    }
  };

  const handleBackground = () => {
    switch (location.pathname) {
      case '/login':
        return { backgroundImage: `url(${loginBackgroundImage})` };

      case '/registration':
      case '/registration-rs':
        return { backgroundImage: `url(${registrationBackgroundImage})`, display: 'flex', justifyContent: 'flex-end' };

      case '/subscription/shop/registration':
        return { backgroundImage: `url(${shopRegistrationBackgroundImage})` };

      default:
        return { background: '#fbf4ed' };
    }
  };

  return (
    <>
      {handleGroupImages() && (
        <div className={classNames(styles.content)} style={handleBackground()}>
          {handleGroupImages()}
        </div>
      )}
    </>
  );
};
