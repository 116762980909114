import React from 'react';

import {
  micrositeBadgePackage,
  micrositePackage,
  quickShopBadgePackage,
  quickShopPackage,
} from '../../../assets/images';
import { PaymentIntent } from '../../../enums/packages';
import { IPackages } from '../../../models/packages';

const packagePriceByCurrency = (property: string, currency: string) => {
  switch (property) {
    case 'microsite':
      return currency === 'CHF' ? '149,00 CHF' : '129,00 EUR';
    case 'quickshop':
      return currency === 'CHF' ? '109,00 CHF' : '99,00 EUR';

    default:
      return '';
  }
};

const packageDiscountPriceByCurrency = (property: string, currency: string) => {
  switch (property) {
    case 'microsite':
      return currency === 'CHF' ? '74,50 CHF' : '68,45 EUR';
    case 'quickshop':
      return currency === 'CHF' ? '54,50 CHF' : '45,55 EUR';

    default:
      return '';
  }
};

const paymentIntent = (currency: string) => {
  switch (currency) {
    case 'CHF':
      return PaymentIntent.PAYMENT_CURRENCY_SWISS;
    case 'EUR':
      return PaymentIntent.PAYMENT_CURRENCY_EURO;

    default:
      return '';
  }
};

const totalPrices = (currency: string) => {
  switch (currency) {
    case 'CHF':
      return {
        discountPrice: '129,00 CHF',
        mounthPrice: '10,75 CHF',
        totalPrice: '258,00 CHF',
      };
    case 'EUR':
      return {
        discountPrice: '114,00 EUR',
        mounthPrice: '9,50 EUR',
        totalPrice: '228,00 EUR',
      };
    default:
      return {
        discountPrice: '129,00 CHF',
        mounthPrice: '10,75 CHF',
        totalPrice: '258,00 CHF',
      };
  }
};

export const packages = (currency: string): IPackages => {
  return {
    prices: totalPrices(currency),
    paymentIntent: paymentIntent(currency),
    activePackages: [
      {
        key: 1,
        title: 'Lokavor Microsite',
        titleImage: <img src={micrositeBadgePackage} alt="microsite" />,
        subTitle: 'Sei präsent auf dem Kundenhandy',
        list: [
          'Mach Dein Geschäft sichtbar',
          'Verwandle Kunden in Followerr',
          'Triff sie dort, wo sie sich bewegen',
          'Halte sie auf dem Laufenden',
          'Zeige Deine Top-Angebote',
        ],

        price: packagePriceByCurrency('microsite', currency),
        discountPrice: packageDiscountPriceByCurrency('microsite', currency),
        styles: {
          backgroundImage: `url(${micrositePackage})`,
        },
      },

      {
        key: 2,
        title: 'Lokavor QuickShop',
        titleImage: <img src={quickShopBadgePackage} alt="quickshop" />,
        subTitle: 'Online verkauft, abgeholt im Laden',
        list: [
          'Komplett per Handy bedienbar',
          'in Echtzeit Produkte einstellen',
          'online Bezahlung oder bei Abholung',
          'Halte sie auf dem Laufenden',
          'keine Zusatzgeräte im Laden nötig',
        ],
        styles: {
          backgroundImage: `url(${quickShopPackage})`,
        },
        discountPrice: packageDiscountPriceByCurrency('quickshop', currency),
        price: packagePriceByCurrency('quickshop', currency),
      },
    ],
  };
};

export const packagesLokalac = (currency: string): IPackages => {
  return {
    prices: totalPrices(currency),
    paymentIntent: paymentIntent(currency),
    activePackages: [
      {
        key: 1,
        title: 'Lokavor Microsite',
        titleImage: <img src={micrositeBadgePackage} alt="microsite" />,
        subTitle: 'Budite prisutni na mobitelu kupca',
        list: [
          'Učinite svoje poslovanje vidljivim',
          'Pretvorite klijente u sljedbenike',
          'Upoznajte ih gdje se kreću',
          'Obavještavajte ih',
          'Pokažite svoje vrhunske ponude',
        ],

        price: packagePriceByCurrency('microsite', currency),
        discountPrice: packageDiscountPriceByCurrency('microsite', currency),
        styles: {
          backgroundImage: `url(${micrositePackage})`,
        },
      },

      {
        key: 2,
        title: 'Lokavor QuickShop',
        titleImage: <img src={quickShopBadgePackage} alt="quickshop" />,
        subTitle: 'Prodano online, preuzeto u trgovini',
        list: [
          'Potpuno upravljiv putem mobilnog telefona',
          'postaviti proizvode u stvarnom vremenu',
          'online plaćanje ili pri preuzimanju',
          'Obavještavajte ih',
          'nisu potrebni dodatni uređaji u trgovini',
        ],
        styles: {
          backgroundImage: `url(${quickShopPackage})`,
        },
        discountPrice: packageDiscountPriceByCurrency('quickshop', currency),
        price: packagePriceByCurrency('quickshop', currency),
      },
    ],
  };
};
