import React from 'react';

import styles from './styles.module.scss';
import { lokavorLogo } from '../../../assets/images';

const SuccessCheckoutLokalac: React.FC = () => {
  return (
    <div className={styles.popUpContent}>
      <img src={lokavorLogo} alt="lokavorLogo" />

      <span className={styles.title}>Dankeschön!</span>
      <span>Wir haben Deine Zahlung erhalten.</span>
    </div>
  );
};

export default SuccessCheckoutLokalac;
