import { USER_MAN_HOSTNAME } from '../../core/axios/environment';

export const registrationEndPoint = (queryEndpoint: string): string => {
  return `${USER_MAN_HOSTNAME}/registration${queryEndpoint}`;
};

export const updateUserWithAmbassadorEndPoint = (queryEndpoint: string): string => {
  return `${USER_MAN_HOSTNAME}/updateUserWithAmbassadorId/${queryEndpoint}`;
};

export const confirmUserRegistrationEndPoint = `${USER_MAN_HOSTNAME}/confirm-registration`;
