const payShopSubscriptionViaInvoiceEndpoint = `https://user-management.panex.cloud/subscriptions/pay-via-invoice`;
const createShopSubscriptionEndpoint = `https://user-management.panex.cloud/subscriptions`;
const createPaymentIntentEndpoint = `https://user-management.panex.cloud/subscriptions`;
const sendSuccessfulPaymentEmailEndpoint = `https://user-management.panex.cloud/subscriptions/successfulStripePayment`;
const sendUnsuccessfulPaymentEmailEndpoint = `https://user-management.panex.cloud/subscriptions/unsuccessfulStripePayment`;

export const payShopSubscriptionViaInvoiceConfig = {
  url: payShopSubscriptionViaInvoiceEndpoint,
  method: 'POST',
};

export const createShopSubscriptionConfig = {
  url: createShopSubscriptionEndpoint,
  method: 'POST',
};

export const createPaymentIntent = {
  url: createPaymentIntentEndpoint,
  method: 'POST',
};

export const sendSuccessfulPaymentEmail = {
  url: sendSuccessfulPaymentEmailEndpoint,
  method: 'POST',
};

export const sendUnsuccessfulPaymentEmail = {
  url: sendUnsuccessfulPaymentEmailEndpoint,
  method: 'POST',
};
