import React, { useEffect, useState, LegacyRef } from 'react';

import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import styles from './styles.module.scss';

import '../../assets/styles/css-animations.css';
import { spinnerLoader } from '../../assets/images';

export interface IButton {
  buttonContentStyles?: StyleSheet | string;
  buttonStyles?: StyleSheet | string;
  callbackOnClick?: () => void;
  errors?: boolean;
  label?: string;
  isLoading?: boolean;
  animatedColor?: string;
  disabled?: boolean;
  toogleButton?: boolean;
  buttonRef?: LegacyRef<HTMLButtonElement>;
}

export const Button: React.FC<IButton> = ({
  buttonContentStyles,
  buttonStyles,
  callbackOnClick,
  errors,
  label,
  isLoading = false,
  animatedColor,
  disabled = false,
  buttonRef,
}: IButton) => {
  const [toggleButton, setToggleButton] = useState(false);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (errors || !isLoading) {
      setLoader(false);
      setToggleButton(false);
    }
  }, [loader, errors, isLoading]);

  const handleTransition = (type: string) => {
    if (isLoading) {
      if (type === 'onEntered' && !errors) setLoader(true);
      else {
        setLoader(false);
        setToggleButton(false);
      }
    } else {
      setToggleButton(false);
    }
  };

  const onSubmitFunction = () => {
    setToggleButton(!toggleButton);

    setTimeout(() => callbackOnClick && callbackOnClick(), 500);
  };

  return (
    <div className={classNames(styles.buttonContent, buttonContentStyles)}>
      <CSSTransition
        in={toggleButton}
        timeout={500}
        classNames="button"
        unmountOnExit
        onEntered={() => handleTransition('onEntered')}
        onExited={() => handleTransition('onExited')}
      >
        <div className={classNames(styles.animationBackground, animatedColor)} />
      </CSSTransition>
      <button
        ref={buttonRef}
        disabled={disabled}
        type="button"
        className={classNames(styles.button, buttonStyles)}
        onClick={onSubmitFunction}
      >
        {loader ? <img src={spinnerLoader} alt="spinner" /> : label}
      </button>
    </div>
  );
};
