import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Row, Col, Visible } from 'react-grid-system';

import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import styles from './styles.module.scss';
import '../../assets/styles/css-animations.css';

import { PopUp } from '../../components/modal';
import { AppState } from '../../store/rootReducer';
import { setOpenModalAction } from '../../store/modal';
import PaymentForm from './PaymentForm';
import SuccessCheckout from '../subscription/checkout/successCheckout';
import { deleteShop } from '../../store/shop/actions';
import DiscardSubscription from '../subscription/checkout/discardSubscription';

interface SalesProccesDto {
  lokavorProductPriceId: string;
  shopId: number;
  // Add other fields as necessary
}

const stripePromise: Promise<Stripe | null> = loadStripe(
  'pk_live_51Ij4k6L4O7UsyRO011RwCe4ImH7EMcFOTNwa1QjBXYv18JPnqhNuSDij51bxVyuyQ4VTL08fzETU1aoadQUg7nN600BDGM3AOi',
  {
    locale: 'de',
  }
);

const Payment: React.FC = () => {
  const { open, source, message, component } = useSelector((state: AppState) => state.modal);
  const { clientSecret, clientSecretStatus } = useSelector((state: AppState) => state.subscription);
  const [isNavigationBlocked, setIsNavigationBlocked] = useState(true);
  const { shopId } = useSelector((state: AppState) => state.subscription);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(true));

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const history = useHistory();

  useEffect(() => {
    // Block navigation and handle the back button attempt
    // @ts-ignore
    const unblock = history.block((location, action) => {
      if (action === 'POP' && isNavigationBlocked) {
        console.log('User tried to go back!');
        dispatch(setOpenModalAction({ open: true, source: 'backHandle', component: 'DISCARD_SUBSCRIPTION' }));
        return false;

        // Here you can add any action you want to perform when the user tries to navigate back
      }
      // Always return false to effectively disable all navigations
      return true;
    });

    return () => {
      unblock(); // Clean up the block when the component is unmounted
    };
  }, [history, dispatch, isNavigationBlocked]);

  const handlePrimaryButton = () => {
    // Perform your action here, then navigate back
    console.log('Handling primary button action');
    if (shopId) {
      dispatch(deleteShop(shopId));
    }
    setIsNavigationBlocked(false); // Disable navigation blocking
    dispatch(setOpenModalAction({ open: false, source: '' })); // Close the modal
    history.goBack(); // Navigate back programmatically
  };

  return (
    <>
      <Row className={styles.paymentContent}>
        <div className={styles.container}>
          {/* DESKTOP PREVIEW */}
          <div style={{ opacity: 0 }} className={classNames(isLoading && styles.fadeIn)}>
            {clientSecret ? (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <PaymentForm />
              </Elements>
            ) : (
              <p>Loading...</p> // Placeholder while loading the clientSecret
            )}
          </div>
        </div>
      </Row>
      <PopUp
        open={open && source === 'backHandle'}
        isSecondaryButton
        modalContent={
          <div className={styles.checkoutPopup}>
            {component === 'DISCARD_SUBSCRIPTION' ? <DiscardSubscription /> : message}
          </div>
        }
        primaryButtonText="Ok"
        secondaryButtonText="Back"
        handlePrimaryButton={handlePrimaryButton}
      />

      <PopUp
        open={open && source === 'subscriptionCheckout'}
        modalContent={
          <div className={styles.checkoutPopup}>{component === 'SUCCESS_CHECKOUT' ? <SuccessCheckout /> : message}</div>
        }
        primaryButtonText="Ok"
        handlePrimaryButton={() => {
          if (component === 'SUCCESS_CHECKOUT') history.push('/dashboard');

          dispatch(setOpenModalAction({ open: false, source: '' }));
        }}
      />
    </>
  );
};

export default Payment;
