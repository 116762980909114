import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ErrorTypes } from '../../enums/errorType';
import { IUserLogin, UserInfoResponse } from '../../models/auth';
import history from '../../core/history';
import { setOpenModalAction } from '../modal';
import { loginEndPoint, validateTokenConfig } from './routes';

import httpClient from '../../core/axios';

// Verify access token action
export const verifyAccessTokenAction = createAsyncThunk('user/verifyTokenAction', async () => {
  const userData: UserInfoResponse = await httpClient(validateTokenConfig());

  return userData.data;
});

// Login Action
export const loginAction = createAsyncThunk('user/loginAction', async (loginUserData: IUserLogin, { dispatch }) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      'Accept-Language': 'DE',
    };

    const response = await axios.post(
      loginEndPoint,
      {
        username: loginUserData.email,
        password: loginUserData.password,
      },
      { headers }
    );

    localStorage.setItem('access_token', response.data.access_token);
    localStorage.setItem('refresh_token', response.data.refresh_token);

    const userInfo = await dispatch(verifyAccessTokenAction()).unwrap();

    if (userInfo.shopOwner) history.push('/dashboard');
    else history.push('/sei-dabei');

    return response.data;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response)
      if (err.response.data.exceptionType === 'USER_DISABLED') {
        dispatch(setOpenModalAction({ open: true, source: 'login', component: 'USER_DISABLED' }));
      } else {
        dispatch(
          setOpenModalAction({
            open: true,
            source: 'login',
            message:
              ErrorTypes[err.response.data.exceptionType as keyof typeof ErrorTypes] || err?.response?.data?.message,
          })
        );
      }

    return Promise.reject();
  }
});
