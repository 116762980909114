import { createSlice } from '@reduxjs/toolkit';

interface ILottieStore {
  active: boolean;
}

const initialState: ILottieStore = {
  active: false,
};

export const lottieSlice = createSlice({
  name: 'lottie',
  initialState,
  reducers: {
    setLottieActive: (state, action) => ({
      ...state,
      active: action.payload,
    }),
  },
});

export const { setLottieActive } = lottieSlice.actions;
