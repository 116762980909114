import React, { useEffect, useState, useRef, useMemo } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';

import L, { Marker as MarkerType } from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import { ReactComponent as Spinner } from '../../assets/images/button/spinnerLoader.svg';

import { geocodeAddress, IGeocodeAddress, reverseGeocode } from '../../services/locationService';

import styles from './styles.module.scss';

import 'leaflet/dist/leaflet.css';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [31, 51], // size of the icon
  iconAnchor: [20, 51], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -51], // point fro
});

L.Marker.prototype.options.icon = DefaultIcon;

interface IAddressLocationOnMap {
  address: string;
  callbackSetShopPosition: (shopPosition: IGeocodeAddress) => void;
}

export const AddressLocationOnMap: React.FC<IAddressLocationOnMap> = ({
  address,
  callbackSetShopPosition,
}: IAddressLocationOnMap) => {
  const [shopPosition, setShopPosition] = useState<IGeocodeAddress>({
    location: {
      lat: 0,
      lng: 0,
    },
    country: '',
  });

  const markerRef = useRef<MarkerType>(null);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await geocodeAddress(address);

        if (result?.location) {
          setShopPosition(result);

          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    };

    fetch();
  }, [address]);

  useEffect(() => {
    if (!loading) callbackSetShopPosition(shopPosition as IGeocodeAddress);
  }, [shopPosition, loading, callbackSetShopPosition]);

  const eventHandlers = useMemo(
    () => ({
      async dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setShopPosition({ ...shopPosition, location: marker.getLatLng() });

          const reverseGeocodeInfo = await reverseGeocode(marker.getLatLng());

          callbackSetShopPosition({ country: reverseGeocodeInfo.country, location: marker.getLatLng() });
        }
      },
    }),
    [shopPosition, callbackSetShopPosition]
  );

  return (
    <div className={styles.registrationPopUpContainer}>
      {loading && <Spinner />}

      {shopPosition.location && !loading && (
        <>
          <span>
            Falls die Adressposition nicht genau stimmt, kannst Du in die Karte zoomen und den Marker auf die korrekte
            Position bewegen.
          </span>
          <MapContainer center={shopPosition.location} zoom={13} scrollWheelZoom={false} className={styles.map}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <Marker draggable position={shopPosition.location} eventHandlers={eventHandlers} ref={markerRef} />
          </MapContainer>
          <span>Bitte bestätige die Adresse.</span>
        </>
      )}

      {!shopPosition?.location && !loading && <span>We can not find your location</span>}
    </div>
  );
};
