import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { Col } from 'react-grid-system';

import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';

import styles from './styles.module.scss';

import { IUserRegistration } from '../../models/registration';
import { Button } from '../../components/button';
import { setLottieActive } from '../../store/lottie';
import { registrationAction } from '../../store/user/actions';
import { AppState } from '../../store/rootReducer';

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Bitte Email-adresse eingeben')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Bitte gib eine gültige Emailadresse ein'
    ),
  firstName: yup.string().min(2, 'Bitte Vornamen eingeben').required(),
  lastName: yup.string().min(2, 'Bitte Nachnamen eingeben').required(),

  password: yup
    .string()
    .required('Bitte passwort eingeben')
    .max(30, 'Dein Passwort ist zu lang.')
    .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/, 'Min 6 Zeichen, min. 1 Großbuchstabe, min. 1 Zahl'),

  matchingPassword: yup
    .string()
    .min(6, 'Bitte Passwortwiederholung eingeben')
    .required()
    .oneOf([yup.ref('password'), null], 'Bitte Passwortwiederholung eingeben'),
});

const RegistrationForm: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { registrationError } = useSelector((state: AppState) => state.user);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserRegistration>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IUserRegistration) => {
    dispatch(setLottieActive(true));

    dispatch(registrationAction({ ...data, searchParameters: location.search }));
  };

  return (
    <>
      <Col md={10} sm={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className={styles.errorMessage}>{errors.email?.message}</p>

          <input {...register('email')} placeholder="Email *" className={errors.email && styles.error} />

          <p className={styles.errorMessage}>{errors.firstName?.message}</p>

          <input {...register('firstName')} placeholder="Vorname *" className={errors.firstName && styles.error} />

          <p className={styles.errorMessage}>{errors.lastName?.message}</p>

          <input {...register('lastName')} placeholder="Nachname *" className={errors.lastName && styles.error} />

          <p className={styles.errorMessage}>{errors.password?.message}</p>

          <input
            {...register('password')}
            placeholder="Passwort *"
            className={errors.password && styles.error}
            type="password"
            autoComplete="password"
          />

          <p className={styles.errorMessage}>{errors.matchingPassword?.message}</p>

          <input
            {...register('matchingPassword')}
            placeholder="Passwort bestätigen *"
            className={errors.matchingPassword && styles.error}
            type="password"
            autoComplete="matchingPassword"
          />
        </form>
      </Col>

      <Col md={2} className={styles.termsContent}>
        <span>Pflichtfelder*</span>
      </Col>

      <Col md={4} className={styles.buttonContent}>
        <Button
          isLoading
          callbackOnClick={handleSubmit(onSubmit)}
          errors={Object.keys(errors).length > 0 || registrationError}
          label="Anmelden"
        />
      </Col>
    </>
  );
};

export default RegistrationForm;
