import axios from 'axios';
import { LatLngLiteral } from 'leaflet';
import { MapBoxProvider, OpenStreetMapProvider } from 'leaflet-geosearch';

const apiKeyMapBox = 'pk.eyJ1IjoibWxhZGVuOTMiLCJhIjoiY2p2M2Z1dmplMDBxZjRlcDlibmJ3MWR6diJ9.6BcbNKwYhY8lSlMcekCT8Q'; // map box provider

// OPEN STREET
const provider = new MapBoxProvider({
  params: {
    access_token: apiKeyMapBox,
  },
});

// const provider = new OpenStreetMapProvider();

// { params: { countrycodes: '__' } }

const GEOCODE_URL = 'https://nominatim.openstreetmap.org/reverse';

export interface IGeocodeAddress {
  location: LatLngLiteral;
  country: string;
}

export const geocodeAddress = async (address: string): Promise<IGeocodeAddress> => {
  try {
    const results = await provider.search({ query: address });

    if (results[0].raw) {
      return {
        location: {
          lat: Number(results[0].raw.center[1]),
          lng: Number(results[0].raw.center[0]),
        },
        country: results[0].label.split(',').pop()?.trim() || '',
      };
    }

    return await Promise.reject();
  } catch (e) {
    return Promise.reject();
  }
};

export const reverseGeocode = async (coordinates: LatLngLiteral): Promise<IGeocodeAddress> => {
  try {
    const results = await axios.get(`${GEOCODE_URL}?lat=${coordinates.lat}&lon=${coordinates.lng}&format=json`);

    return results.data.address;
  } catch (e) {
    return Promise.reject();
  }
};
