import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TimeField from 'react-simple-timefield';
import { Button } from '../../components/button';
import { IOpenHoursDto } from '../../models/shop';
import { AppState } from '../../store/rootReducer';
import { setShopRegistrationDataAction } from '../../store/shop';
import styles from './styles.module.scss';

interface IShopTypes {
  handleBackButton: () => void;
}

export const OpenHours: React.FC<IShopTypes> = ({ handleBackButton }: IShopTypes) => {
  const dispatch = useDispatch();

  const { shopRegistration } = useSelector((state: AppState) => state.shop);

  const [localOpenHoursDto, setLocalOpenHoursDto] = useState<IOpenHoursDto[]>(shopRegistration?.openHoursDto); // WILL BE  REFACTORED

  const handleChange = (dayOfWeek: string | undefined, openHoursObject: IOpenHoursDto) => {
    setLocalOpenHoursDto(
      localOpenHoursDto.map((data) => {
        if (data.dayOfWeek === dayOfWeek) return { ...data, ...openHoursObject };
        return data;
      })
    );
  };

  const handleChangeNonWorkingDay = (dayOfWeek: string | undefined, openHoursObject: IOpenHoursDto) => {
    setLocalOpenHoursDto(
      localOpenHoursDto.map((data) => {
        if (data.dayOfWeek === dayOfWeek) return { ...data, ...openHoursObject };
        return data;
      })
    );
  };

  const handleSubmit = () => {
    dispatch(setShopRegistrationDataAction({ openHoursDto: localOpenHoursDto }));

    handleBackButton();
  };

  return (
    <div className={styles.content}>
      <div className={styles.headerContainer}>Öffnungszeiten eingeben</div>

      <div className={styles.openHoursContainer}>
        <div className={classNames(styles.dayRow)}>
          <div className={classNames(styles.workingHoursContainer, styles.dayRowHeader)}>
            <span />
            <div>
              <input value="öffnet" readOnly />
              <input value="schliesst" readOnly />
            </div>
          </div>

          <div className={classNames(styles.breakHoursContainer, styles.dayRowHeader)}>
            <div>
              <input value="Pause einfügen" readOnly className={styles.inputWidth} />
            </div>
          </div>

          <div className={styles.closeIcon} />
        </div>
        {localOpenHoursDto?.map((data: IOpenHoursDto, i) => {
          return (
            <div key={i} className={classNames(styles.dayRow)}>
              <div className={classNames(styles.workingHoursContainer, data.nonWorkingDay && styles.disabled)}>
                <span>{data.name}</span>

                <div>
                  <TimeField
                    value={data.from}
                    onChange={(e) => {
                      handleChange(data.dayOfWeek, { from: e.target.value });
                    }}
                    colon=":"
                  />
                  -
                  <TimeField
                    value={data.to}
                    onChange={(e) => handleChange(data.dayOfWeek, { to: e.target.value })}
                    colon=":"
                  />
                </div>
              </div>

              <div className={classNames(styles.breakHoursContainer, data.nonWorkingDay && styles.disabled)}>
                <div>
                  <TimeField
                    value={data.breakFrom}
                    onChange={(e) => handleChange(data.dayOfWeek, { breakFrom: e.target.value })}
                    colon=":"
                  />
                  -
                  <TimeField
                    value={data.breakTo}
                    onChange={(e) => handleChange(data.dayOfWeek, { breakTo: e.target.value })}
                    colon=":"
                  />
                </div>
              </div>

              <div
                className={styles.closeIcon}
                role="button"
                tabIndex={0}
                onClick={() => handleChangeNonWorkingDay(data.dayOfWeek, { nonWorkingDay: !data.nonWorkingDay })}
              >
                X
              </div>
            </div>
          );
        })}
      </div>

      <div className={styles.buttonOHandSTContainer}>
        <Button callbackOnClick={handleSubmit} label="Weiter" />

        <div role="button" tabIndex={0} className={styles.backText} onClick={handleBackButton}>
          Abbrechen
        </div>
      </div>
    </div>
  );
};
