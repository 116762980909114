import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Controller, useForm } from 'react-hook-form';
import { Col } from 'react-grid-system';

import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';

import Select from 'react-select';
import styles from './styles.module.scss';

import { IUserRegistration } from '../../models/registration';
import { Button } from '../../components/button';
import { setLottieActive } from '../../store/lottie';
import { registrationActionLokalac } from '../../store/user/actions';
import { AppState } from '../../store/rootReducer';

const selectStyles = {
  menu: (provided: any) => ({
    ...provided,
    marginTop: 0,
    borderRadius: 15,
    userSelect: 'none',
    width: '100%',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    cursor: 'pointer',
    fontSize: 12,
    borderRadius: 36,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: 'none',
    fontSize: 13,
    borderRadius: 36,
    marginTop: 15,
    height: 55,
    backgroundColor: 'transparent',
    border: '1px solid #000',
    fontFamily: 'Montserrat-Medium',
    paddingLeft: 15,
  }),
  container: (provided: any) => ({
    ...provided,
    width: '100%',
    fontFamily: 'Montserrat-Medium',
  }),
};
const schema = yup.object().shape({
  email: yup
    .string()
    .required('Molimo unesite email adresu')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Molimo unesite važeću e-mail adresu'
    ),
  firstName: yup.string().min(2, 'Molimo unesite ime').required(),
  lastName: yup.string().min(2, 'Molimo unesite prezime').required(),
  country: yup.string().required('Molimo odaberite drzavu'),
  terms: yup
    .bool() // use bool instead of boolean
    .oneOf([true], 'You must accept the terms and conditions'),
  password: yup
    .string()
    .required('Molimo unesite lozinku')
    .max(30, 'Vaša lozinka je preduga.')
    .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/, 'Min. 6 znakova, min. 1 veliko slovo, min. 1 broj'),

  matchingPassword: yup
    .string()
    .min(6, 'Molimo ponovno unesite lozinku')
    .required()
    .oneOf([yup.ref('password'), null], 'Molimo ponovno unesite lozinku'),
});

interface SelectOption {
  value: string;
  label: string;
}

const RegistrationFormLokalac: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [termsChecked, setTermsChecked] = useState(false);

  const { registrationError } = useSelector((state: AppState) => state.user);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserRegistration>({
    resolver: yupResolver(schema),
  });
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(null);

  const onSubmit = (data: IUserRegistration) => {
    dispatch(setLottieActive(true));
    const selectedValue = selectedOption ? (selectedOption as SelectOption).value : undefined;
    dispatch(registrationActionLokalac({ ...data, country: selectedValue, searchParameters: location.search }));
  };

  const selectOptions: SelectOption[] = [
    { value: 'SERBIA', label: 'Srbija' },
    { value: 'BOSNIA_AND_HERZEGOVINA', label: 'BiH' },
  ];

  return (
    <>
      <Col md={10} sm={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className={styles.errorMessage}>{errors.email?.message}</p>
          <input {...register('email')} placeholder="Email *" className={errors.email && styles.error} />
          <p className={styles.errorMessage}>{errors.firstName?.message}</p>
          <input {...register('firstName')} placeholder="Ime *" className={errors.firstName && styles.error} />
          <p className={styles.errorMessage}>{errors.lastName?.message}</p>
          <input {...register('lastName')} placeholder="Prezime *" className={errors.lastName && styles.error} />
          <p className={styles.errorMessage}>{errors.password?.message}</p>
          <input
            {...register('password')}
            placeholder="Lozinka *"
            className={errors.password && styles.error}
            type="password"
            autoComplete="password"
          />
          <p className={styles.errorMessage}>{errors.matchingPassword?.message}</p>
          <input
            {...register('matchingPassword')}
            placeholder="Potvrdi lozinku *"
            className={errors.matchingPassword && styles.error}
            type="password"
            autoComplete="matchingPassword"
          />
          <Controller
            name="country"
            control={control}
            rules={{ required: 'Molimo odaberite državu.' }}
            render={({ field }) => (
              <>
                <Select
                  styles={selectStyles}
                  options={selectOptions}
                  value={selectedOption}
                  onChange={(option) => {
                    setSelectedOption(option);
                    field.onChange(option?.value); // This is important to update the form value
                  }}
                  placeholder="Odaberi državu *"
                />
                {errors.country && <p className={styles.errorMessage}>{errors.country.message}</p>}
              </>
            )}
          />
          <div className={styles.checkboxText}>
            <input
              {...register('terms')}
              style={{ height: 20, width: 20, marginLeft: 20, marginRight: 10 }}
              type="checkbox"
              checked={termsChecked}
              onChange={() => setTermsChecked(!termsChecked)}
              id="termsCheckbox" // Add a unique id here
            />
            Prihvatam uslove korišćenja
          </div>
          {errors.terms && <p className={styles.errorMessage}>Molimo prihvatite uslove korišćenja.</p>}
        </form>
      </Col>

      <Col md={2} className={styles.termsContent}>
        <span>Obavezna polja*</span>
      </Col>

      <Col md={4} className={styles.buttonContent}>
        <Button
          isLoading
          callbackOnClick={handleSubmit(onSubmit)}
          errors={Object.keys(errors).length > 0 || registrationError || selectedOption == null}
          label="Registruj"
        />
      </Col>
    </>
  );
};

export default RegistrationFormLokalac;
