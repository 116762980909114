import { IAxiosConfig } from '../../models/axios';
import { OPEN_ID_URL, USER_MAN_HOSTNAME } from '../../core/axios/environment';

export const loginEndPoint = `${USER_MAN_HOSTNAME}/users/login`;

export const validateTokenConfig = (): IAxiosConfig => {
  return {
    url: `${OPEN_ID_URL}/userinfo`,
    method: 'POST',
  };
};
