import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface IRadioButton {
  active: boolean;
  disabled?: boolean;
  callbackClickOnRadioButton: () => void;
  label: string;
  radioButtonContentStyles?: StyleSheet | string;
}
const RadioButton: React.FC<IRadioButton> = ({
  radioButtonContentStyles,
  active,
  callbackClickOnRadioButton,
  disabled,
  label,
}: IRadioButton) => {
  return (
    <div className={classNames(styles.content, radioButtonContentStyles)}>
      <div
        role="button"
        tabIndex={0}
        className={classNames(styles.radioButtonOutside, disabled && styles.disabled)}
        onClick={!disabled ? callbackClickOnRadioButton : undefined}
      >
        <div className={classNames(styles.radioButtonInside, active && styles.active, disabled && styles.disabled)} />
      </div>
      <span>{label}</span>
    </div>
  );
};

export default RadioButton;
