import React from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { setOpenModalAction } from '../../store/modal';

// STYLES
import styles from './styles.module.scss';
import './index.css';
import { Button } from '../button';

interface IPopUp {
  modalContent: React.ReactElement;
  footer?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  handlePrimaryButton?: () => void;
  handleSecondaryButton?: () => void;
  isSecondaryButton?: boolean;
  open: boolean;
  isLoadingButton?: boolean;
  disabledButton?: boolean;
  buttonErrors?: boolean;
  customStylesModal?: StyleSheet | string | undefined;
  customOverlayModalStyles?: StyleSheet | string | undefined;
  buttonsColumnPosition?: boolean;
  title?: string;
  animation?: string;
}

export const PopUp: React.FC<React.PropsWithChildren<IPopUp>> = ({
  footer = true,
  handlePrimaryButton,
  primaryButtonText,
  secondaryButtonText = '',
  isSecondaryButton = false,
  modalContent,
  open,
  isLoadingButton = false,
  disabledButton = false,
  buttonErrors = false,
  customStylesModal,
  buttonsColumnPosition = false,
  title,
  customOverlayModalStyles,
  handleSecondaryButton,
  animation = 'scaleUp',
}: React.PropsWithChildren<IPopUp>) => {
  const dispatch = useDispatch();

  const clickOnSecondaryButton = () => {
    if (handleSecondaryButton) handleSecondaryButton();
    else {
      dispatch(setOpenModalAction({ open: false, source: '' }));
    }
  };

  return (
    <Modal
      closeTimeoutMS={300}
      isOpen={open}
      className={{
        base: classNames(styles.modal, customStylesModal),
        afterOpen: styles[animation],
        beforeClose: styles[`${animation}Reverse`],
      }}
      overlayClassName={classNames(styles.overlayModal, customOverlayModalStyles)}
      onRequestClose={() => clickOnSecondaryButton()}
      ariaHideApp={false}
      contentLabel="Modal"
    >
      {title && (
        <header>
          <span>{title}</span>
        </header>
      )}
      <div className={styles.content}>{modalContent}</div>

      {footer && (
        <footer className={classNames(styles.footer, buttonsColumnPosition && styles.buttonsColumnPosition)}>
          {isSecondaryButton && (
            <Button
              label={secondaryButtonText || ''}
              callbackOnClick={() => clickOnSecondaryButton()}
              buttonStyles={styles.secondaryButtonStyles}
              buttonContentStyles={styles.buttonContentStyles}
              animatedColor={styles.animatedSecondaryButtonColor}
            />
          )}

          <Button
            errors={buttonErrors}
            disabled={disabledButton}
            isLoading={isLoadingButton}
            label={primaryButtonText}
            callbackOnClick={handlePrimaryButton}
          />
        </footer>
      )}
    </Modal>
  );
};
