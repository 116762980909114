import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Row, Col, Visible } from 'react-grid-system';

import styles from './styles.module.scss';
import '../../assets/styles/css-animations.css';

import RegistrationForm from './RegistrationFormLokalac';
import { PopUp } from '../../components/modal';
import { AppState } from '../../store/rootReducer';
import { setOpenModalAction } from '../../store/modal';
import { setLottieActive } from '../../store/lottie';
import history from '../../core/history';
import { realBackground } from '../../assets/images';
import { urlSearchParameters } from '../../utils/urlSearchParams';

declare global {
  interface Window {
    Tawk_API?: any; // Use `any` or a more specific type if you know the structure of Tawk_API
  }
}

const renderSubtitleText = (search: string | undefined) => {
  const query = urlSearchParameters(search);

  if (query.get('shopId') && query.get('shopName'))
    return `Dobro došli na Lokalac! Nakon uspešne registracije automatski postajete pratilac ${query.get(
      'shopName'
    )} i tako ćete uvek biti u toku sa najnovijim dešavanjima.`;

  if (query.get('shopId') || query.get('source')) {
    return 'Dobrodošli u Lokalac. Registrirajte se ovdje kako biste besplatno koristili našu aplikaciju.';
  }
  if (query.get('voucherId') && query.get('companyName')) {
    return `Dobro došli na Lokalac! Nakon uspešne registracije automatski aktivirate poklon vaučer od ${query.get(
      'companyName'
    )} . Poklon vaučer možete iskoristiti nakon prijave u Lokalac mobilnoj aplikaciji, u odeljku Vaučeri.`;
  }
  if (query.get('voucherId')) {
    return `Dobro došli na Lokalac! Nakon uspešne registracije automatski aktivirate poklon vaučer. Poklon vaučer možete iskoristiti nakon prijave u Lokalac mobilnoj aplikaciji, u odeljku Vaučeri.`;
  }

  return 'Kako biste mogli koristiti usluge Lokalac, prvo Vam je potreban korisnički račun.';
};

const headerContainer = (location: string | undefined) => {
  return (
    <div className={styles.headerContainer}>
      <Col md={12} xs={12} sm={12}>
        <div className={styles.title}>Registrujte novi korisnički račun</div>
      </Col>
      <Col md={12} sm={12}>
        <div className={styles.subTitle}>{renderSubtitleText(location)}</div>
      </Col>
    </div>
  );
};

const RegistrationLokalac: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  const dispatch = useDispatch();

  const { open, source, message } = useSelector((state: AppState) => state.modal);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(true));

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handlePrimaryButton = () => {
    dispatch(setOpenModalAction({ open: false, source: '' }));
  };

  useEffect(() => {
    // Define the script-behavior function
    // Tawk.to chat script
    const loadTawkScript = (): void => {
      const existingScript: HTMLElement | null = document.getElementById('tawkScript');
      if (!window.Tawk_API && !existingScript) {
        const s1: HTMLScriptElement = document.createElement('script');
        const s0: HTMLScriptElement | null = document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/65e894499131ed19d975c317/1hoa86gb2';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s1.id = 'tawkScript'; // Ensure script tag is identified for potential removal
        if (s0 && s0.parentNode) {
          s0.parentNode.insertBefore(s1, s0);
        }
      }
    };

    loadTawkScript();

    return () => {
      // Remove Tawk.to script when component unmounts
      const tawkScript: HTMLElement | null = document.getElementById('tawkScript');
      if (tawkScript && tawkScript.parentNode) {
        tawkScript.parentNode.removeChild(tawkScript);
      }
    };
  }, []);
  return (
    <>
      <Row className={styles.content}>
        <div className={styles.container}>
          {/* MOBILE PREVIEW */}
          <Visible xs sm>
            <>
              <img
                src=""
                alt="dashboard"
                className={classNames(isLoading && styles.imageResize, styles.mobileBackgroundImage)}
              />
              <div className={classNames(styles.mobileContent, isLoading && styles.contentResize)}>
                <div className={classNames(styles.mobileContainer, isLoading && styles.mobileTranslate)}>
                  {headerContainer(location.search)}

                  <div>
                    <RegistrationForm />
                  </div>
                  <div>
                    <Col md={2} className={styles.termsContent}>
                      <span>
                        Pogledaj te uslove korišćenja{' '}
                        <span
                          aria-hidden="true"
                          onClick={() => {
                            window.location.href = 'https://lokalac.co.rs/terms-bih';
                          }}
                          className={styles.link}
                        >
                          BiH
                        </span>{' '}
                        i{' '}
                        <span
                          aria-hidden="true"
                          onClick={() => {
                            window.location.href = 'https://lokalac.co.rs/terms-rs';
                          }}
                          className={styles.link}
                        >
                          Srbije
                        </span>{' '}
                      </span>
                    </Col>
                  </div>
                </div>
              </div>
            </>
          </Visible>

          {/* DESKTOP PREVIEW */}
          <Visible md lg xl xxl>
            <div style={{ opacity: 0 }} className={classNames(isLoading && styles.fadeIn)}>
              {headerContainer(location.search)}

              <RegistrationForm />

              <Col md={4} className={styles.termsContent}>
                <span>
                  Pogledaj te uslove korišćenja{' '}
                  <span
                    aria-hidden="true"
                    onClick={() => {
                      window.location.href = 'https://lokalac.co.rs/terms-bih';
                    }}
                    className={styles.link}
                  >
                    BiH
                  </span>{' '}
                  i{' '}
                  <span
                    aria-hidden="true"
                    onClick={() => {
                      window.location.href = 'https://lokalac.co.rs/terms-rs';
                    }}
                    className={styles.link}
                  >
                    Srbije.
                  </span>{' '}
                </span>
              </Col>
            </div>
          </Visible>
        </div>
      </Row>

      <PopUp
        // secondaryButtonText="Back"
        // isSecondaryButton
        open={open && source === 'registration'}
        modalContent={<div className={styles.registrationPopUpContent}>{message}</div>}
        primaryButtonText="OK"
        handlePrimaryButton={handlePrimaryButton}
      />
    </>
  );
};

export default RegistrationLokalac;
