import React, { useRef, KeyboardEvent } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import { Col } from 'react-grid-system';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import styles from './styles.module.scss';

import { IUserLogin } from '../../models/auth';
import { Button } from '../../components/button';
import { loginAction } from '../../store/auth/actions';
import { AppState } from '../../store/rootReducer';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(2).required(),
});

const LoginFormLokalac: React.FC = () => {
  const dispatch = useDispatch();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { loginError } = useSelector((state: AppState) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserLogin>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IUserLogin) => {
    dispatch(loginAction(data));
  };

  const handleKeyboardEvent = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && buttonRef) {
      buttonRef.current?.click();
    }
  };

  return (
    <>
      <Col md={12} sm={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className={styles.errorMessage} />

          <input
            {...register('email')}
            placeholder="Email"
            className={errors.email && styles.error}
            onKeyPress={handleKeyboardEvent}
          />

          <p className={styles.errorMessage} />

          <input
            {...register('password')}
            placeholder="Passwort"
            className={errors.password && styles.error}
            type="password"
            autoComplete="password"
            onKeyPress={handleKeyboardEvent}
          />
        </form>
      </Col>

      <Col md={12} sm={12} className={styles.buttonContent}>
        <Button
          isLoading
          callbackOnClick={handleSubmit(onSubmit)}
          errors={Object.keys(errors).length > 0 || loginError}
          label="Login"
          buttonRef={buttonRef}
        />
      </Col>
    </>
  );
};

export default LoginFormLokalac;
